import mapboxgl from 'mapbox-gl';
import * as interpolateHeatMapLayer from 'interpolateheatmaplayer';
import { generateDefaultBoundaryPoints } from '.././HeatMap/generateDefaultBoundaryPoints';

// @deprecated
// interpolateheatmaplayer@1.0.2
// Layer code requires this global.
window.mapboxgl = mapboxgl;

interface HeatMapPoint {
  lon: number;
  lat: number;
  val: number;
}
const featuresToPoints = (
  geoData?: GeoJSON.FeatureCollection<GeoJSON.Point, { value: number }>
): HeatMapPoint[] | undefined =>
  geoData?.features.map(({ properties, geometry }) => ({
    lon: geometry.coordinates[0],
    lat: geometry.coordinates[1],
    val: properties.value,
  }));
interface Opts {
  id: string;
  geoData: GeoJSON.FeatureCollection<GeoJSON.Point, { value: number }>;
  siteBoundary?: GeoJSON.FeatureCollection<GeoJSON.Point, { value: number }>;
  p?: number;
  valueToColor4?: string;
}

export const monitorDataHeatMapLayer = (opts: Opts) => {
  const { id, geoData, siteBoundary, p, valueToColor4 } = opts;

  const dataPoints = featuresToPoints(geoData) || [];

  const boundaryPoints =
    featuresToPoints(siteBoundary) || generateDefaultBoundaryPoints(dataPoints);

  const layer = interpolateHeatMapLayer.create({
    id,
    layerID: id,
    framebufferFactor: 1,
    points: [...dataPoints, ...(boundaryPoints || [])],

    // @deprecated
    // interpolateheatmaplayer@1.0.2
    // Layer code throws when using roi: undefined.
    ...(boundaryPoints ? { roi: boundaryPoints } : {}),

    opacity: 1,
    p: typeof p === 'number' ? p : 3,
    valueToColor4,
  });

  // @deprecated
  // interpolateheatmaplayer@1.0.2
  // Layer code doesn't set the ID.
  if (!layer.id) {
    layer.id = 'heatmap';
  }

  return layer;
};
