import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  APITokenAuthContext,
  useAuth,
  useCallOnNotAuthenticated,
} from '../auth';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react';
import { ComponentType } from 'react';
import { ScreenLoader } from '../../../components/common/Loaders';
import { useCallOnceOnIDTokenGranted } from '../common';

export default function WithAuthenticationRequired<P extends object>({
  Component,
  props,
  options,
  allowAPITokenAuth,
}: {
  Component: ComponentType<P> | ComponentType;
  props?: P;
  options?: WithAuthenticationRequiredOptions;
  allowAPITokenAuth?: boolean;
}): JSX.Element {
  const history = useHistory();
  const { isAuthenticated, isLoading } = useAuth();
  const { returnTo = '/login' } = options || {};
  const [idTokenFetched, setIdTokenFetched] = useState(false);

  const location = useLocation();
  const apiToken = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return query.get('token');
  }, [location]);

  useCallOnNotAuthenticated(() => {
    if (!(allowAPITokenAuth && apiToken)) {
      history.push(returnTo as string);
    }
  }, [returnTo, history, allowAPITokenAuth, apiToken]);

  useCallOnceOnIDTokenGranted(() => setIdTokenFetched(true));

  function displayComponent() {
    if (props) {
      return <Component {...props} />;
    } else {
      // @ts-ignore
      return <Component />;
    }
  }

  if (allowAPITokenAuth && apiToken) {
    return (
      <APITokenAuthContext.Provider value={apiToken}>
        {displayComponent()}
      </APITokenAuthContext.Provider>
    );
  } else if (isLoading || !isAuthenticated || !idTokenFetched) {
    return <ScreenLoader />;
  } else {
    return displayComponent();
  }
}
