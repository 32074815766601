import React from 'react';

export interface IReactParentPropTypes {
  children?: React.ReactNode;
}

export interface IAction {
  type: string;
  payload?: any;
  query?: string;
}

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
}

export interface IStyledComponentPropTypes {
  className?: string;
}

export interface IAPIErrorFormat {
  message: string;
  errors: [];
  status: number;
}

export interface IFile extends File {
  fileName: string;
  path: string;
}

export type fieldType =
  | 'text'
  | 'text-multiple-choice'
  | 'text-multi-line'
  | 'multitext'
  | 'numeric'
  | 'float'
  | 'currency'
  | 'percent'
  | 'rating'
  | 'date'
  | 'timestamp'
  | 'timeofday'
  | 'duration'
  | 'checkbox'
  | 'address'
  | 'phone'
  | 'email'
  | 'userid'
  | 'multiuserid'
  | 'file'
  | 'url'
  | 'dblink'
  | 'ICalendarButton'
  | 'vCardButton'
  | 'predecessor'
  | 'recordid';

export type IDataField = {
  id: number;
  label: string;
  required: boolean;
  fieldType: fieldType;
  isAssetField?: boolean;
  order?: number;
  otherLabels?: string[];
  properties?: {
    decimalPlaces?: number;
    units?: string;
  };
};

export const nameof = <T>(name: keyof T) => name;
