import { Alert } from 'react-bootstrap';
import { IMonitorDataImportJob } from '../../state/modules/monitorDataImportJobs';
import { Variant } from '../../common/types';

export function ImportJobStatusAlert({
  monitorDataImportJob,
}: {
  monitorDataImportJob: IMonitorDataImportJob;
}) {
  return (
    <Alert
      variant={
        monitorDataImportJob?.failed?.length
          ? Variant.SECONDARY
          : Variant.SUCCESS
      }
    >{`Importing ${monitorDataImportJob.processing} of ${
      monitorDataImportJob.startingSize
    } readings. ${
      monitorDataImportJob.startingSize - monitorDataImportJob.processing
    } completed. ${
      monitorDataImportJob?.failed?.length || 0
    } failure(s).`}</Alert>
  );
}
