import React, { createContext, useReducer } from 'react';

import { IAction, IReactParentPropTypes } from '../common/types';
import reducer from './reducer';
import initialState from './initialState';

type IState = typeof initialState;
type IDispatch = (action: IAction) => void;

export type IStoreContextValue = {
  state: IState;
  dispatch: IDispatch;
};

const store = createContext<IStoreContextValue>({
  state: initialState,
  dispatch: (action: IAction) => {},
});

const { Provider } = store;

interface StateProviderPropType extends IReactParentPropTypes {
  value?: IStoreContextValue;
}

function StateProvider({ children, value }: StateProviderPropType) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={value || { state, dispatch }}>{children}</Provider>;
}

export { store, StateProvider };
