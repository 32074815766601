import React, {
  Fragment,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useMemo,
  CSSProperties,
} from 'react';
import moment from 'moment';
import { IDataField } from '../../common/types';
import { MonitorDataListing } from '../../state/modules/monitorDataListing';
import { IMonitorDataFilter } from './Filter';

interface Props extends HTMLAttributes<HTMLDivElement> {
  filter: IMonitorDataFilter;
  sections?: readonly string[];
  fields?: readonly IDataField[];
  listing?: MonitorDataListing;
}

const smallPartStyleNoBorder: CSSProperties = {
  display: 'inline-block',
  margin: '4px 6px',
  whiteSpace: 'nowrap',
};

const smallPartStyle: CSSProperties = {
  ...smallPartStyleNoBorder,
  border: '1px solid lightgrey',
  padding: '0px 2px',
  borderRadius: 4,
};

export const MonitorDataCaption: FunctionComponent<Props> = (props) => {
  const { filter, sections, fields, listing, ...passThroughProps } = props;

  const { siteMonitorClassId, startDate, endDate, fieldId, section, min, max } =
    filter;
  const { siteMonitorClasses } = listing || {};

  const siteMonitorClass = useMemo(
    () =>
      siteMonitorClassId !== undefined
        ? siteMonitorClasses?.find(
            ({ recordId }) => recordId === siteMonitorClassId
          )
        : undefined,
    [siteMonitorClassId, siteMonitorClasses]
  );

  const field = useMemo(
    () =>
      fieldId !== undefined
        ? fields?.find(({ id }) => id === fieldId)
        : undefined,
    [fields, fieldId]
  );

  const parts: ReactNode[] = [];

  const titleParts: ReactNode[] = [];

  if (siteMonitorClass) {
    const { siteName, monitorClass } = siteMonitorClass;

    if (siteName) titleParts.push(siteName);
    if (monitorClass) titleParts.push(monitorClass);
  }

  const units = field?.properties?.units;
  const unitsDisplay = units ? (
    <>
      &nbsp;<small>{units}</small>
    </>
  ) : null;
  const decimalPlaces = field?.properties?.decimalPlaces;
  if (field) {
    titleParts.push(
      <span key="field">
        {field.label}
        {unitsDisplay}
      </span>
    );
  }

  if (titleParts.length) {
    parts.push(
      <h2 key="title">
        {titleParts.map((el, index) =>
          index === 0 ? el : <Fragment key={index}>&mdash;{el}</Fragment>
        )}
      </h2>
    );
  }

  const optsParts: ReactNode[] = [];

  if (section) {
    optsParts.push(<span style={smallPartStyleNoBorder}>{section}</span>);
  }

  if (startDate && endDate) {
    optsParts.push(
      <span key="date" style={smallPartStyle}>
        {moment(startDate).format('LL')}
        &nbsp;&mdash;&nbsp;
        {moment(endDate).format('LL')}
      </span>
    );
  } else if (!startDate && endDate) {
    optsParts.push(
      <span key="date" style={smallPartStyle}>
        Up to {moment(endDate).format('LL')}
      </span>
    );
  } else if (startDate && !endDate) {
    optsParts.push(
      <span key="date" style={smallPartStyle}>
        From {moment(startDate).format('LL')}
      </span>
    );
  }

  const minStr =
    min !== undefined
      ? decimalPlaces !== undefined
        ? min.toFixed(decimalPlaces)
        : min.toString()
      : undefined;
  const maxStr =
    max !== undefined
      ? decimalPlaces !== undefined
        ? max.toFixed(decimalPlaces)
        : max.toString()
      : undefined;

  let selectedRangeDisplay: ReactNode;
  if (minStr && maxStr) {
    selectedRangeDisplay = (
      <>
        {minStr}
        &nbsp;&mdash;&nbsp;
        {maxStr}
      </>
    );
  } else if (!minStr && maxStr) {
    selectedRangeDisplay = <>Up to {maxStr}</>;
  } else if (minStr && !maxStr) {
    selectedRangeDisplay = <>From {minStr}</>;
  }
  if (selectedRangeDisplay)
    optsParts.push(
      <span key="range" style={smallPartStyle}>
        <small>Selected range:&nbsp;</small>
        {selectedRangeDisplay}
        {unitsDisplay}
      </span>
    );

  if (optsParts.length) {
    parts.push(...optsParts);
  }

  if (parts.length === 0) return null;

  return <div {...passThroughProps}>{parts}</div>;
};
