export function getCenter(
  data: GeoJSON.FeatureCollection<GeoJSON.Point>
): [number, number] | undefined {
  if (data.features.length === 0) {
    return undefined;
  }

  const { maxLatitude, minLatitude, maxLongitude, minLongitude } =
    data.features.reduce((acc, { geometry }) => {
      const coordinates = geometry?.coordinates;
      if (coordinates?.length >= 2) {
        const [lat, lng] = coordinates;
        if (acc['maxLatitude'] === undefined || lat > acc['maxLatitude']) {
          acc['maxLatitude'] = lat;
        }
        if (acc['minLatitude'] === undefined || lat < acc['minLatitude']) {
          acc['minLatitude'] = lat;
        }
        if (acc['maxLongitude'] === undefined || lng > acc['maxLongitude']) {
          acc['maxLongitude'] = lng;
        }
        if (acc['minLongitude'] === undefined || lng < acc['minLongitude']) {
          acc['minLongitude'] = lng;
        }
      }
      return acc;
    }, {} as Record<string, number>);

  return [(maxLatitude + minLatitude) / 2, (maxLongitude + minLongitude) / 2];
}
