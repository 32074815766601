import mapboxgl from 'mapbox-gl';
import { RefObject } from 'react';
import { Dictionary } from 'lodash';
import { MAP_DEFAULTS } from '../helpers/HeatMapDefaults';
import { IDataField } from '../../common/types';
import { IMonitorDataFeature } from '../../state/modules/monitorData';

const HIDDEN_FIELDS = ['Related Asset', 'Count Duplicates', 'Related Admin'];

export interface FieldsInfo {
  readonly fieldsById: Dictionary<IDataField>;
  readonly selectedFieldId?: number;
}

export function getFeatureOverlay(
  feature: IMonitorDataFeature,
  fieldsInfoRef: RefObject<FieldsInfo>
) {
  const { href, monitorId, mapId } = feature.properties;

  // MapBox stringifies "properties" properties
  const values = JSON.parse(feature.properties.values as unknown as string);

  const { fieldsById, selectedFieldId } = fieldsInfoRef.current || {};

  const selectedField =
    fieldsById && typeof selectedFieldId === 'number'
      ? fieldsById[selectedFieldId]
      : undefined;

  const mainFieldValue = selectedField ? values[selectedField.id] : undefined;

  const otherValuesFieldIds =
    typeof selectedFieldId === 'number'
      ? Object.keys(values).filter(
          (fieldId) => parseInt(fieldId, 10) !== selectedFieldId
        )
      : Object.keys(values);

  return [
    `<p class="btn btn-link w-100 text-center mb-n3 mt-n2"><a href=${href} target="_blank">${
      mapId || monitorId
    }</a></p>`,
    `<hr>`,
    mainFieldValue !== undefined
      ? `<p class="mt-n2 mb-n1" title="Field #${selectedFieldId}"><strong>${
          selectedField?.label
        }: ${mainFieldValue.toFixed(2)}</strong></p>`
      : '',
    ...otherValuesFieldIds
      .filter((fieldId) => {
        const field = fieldsById?.[fieldId];
        return HIDDEN_FIELDS.indexOf(field?.label as string) === -1;
      })
      .map((fieldId) => {
        const field = fieldsById?.[fieldId];
        const fieldTitle = field?.label || `<i>${fieldId}</i>`;
        const value = values[fieldId];
        const valueDisplay =
          typeof value === 'number' ? value.toFixed(2) : '<i>N/A</i>';
        return `<p class="mb-n1" title="Field #${fieldId}">${fieldTitle}: ${valueDisplay}</p>`;
      }),
  ].join('');
}

interface Opts {
  map: mapboxgl.Map;
  fieldsInfoRef: RefObject<FieldsInfo>;
}
export const addBaseLayers = (opts: Opts) => {
  const { map, fieldsInfoRef } = opts;
  if (!map.getSource('assets')) {
    map.addSource('assets', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }

  if (!map.getLayer(MAP_DEFAULTS.LAYERS.ASSET_POINT.id)) {
    map.addLayer(MAP_DEFAULTS.LAYERS.ASSET_POINT);
    map.on('click', MAP_DEFAULTS.LAYERS.ASSET_POINT.id, ({ features }) => {
      const feature = features![0] as unknown as IMonitorDataFeature;
      new mapboxgl.Popup()
        .setLngLat(feature.geometry.coordinates as [number, number])
        .setHTML(getFeatureOverlay(feature, fieldsInfoRef))
        .addTo(map!);
    });
  }

  if (!map.getLayer(MAP_DEFAULTS.LAYERS.ASSET_LABEL.id)) {
    map.addLayer(MAP_DEFAULTS.LAYERS.ASSET_LABEL);
  }

  /*
  if (!map.getLayer(MAP_DEFAULTS.LAYERS.ASSET_ID.id)) {
    map.addLayer(MAP_DEFAULTS.LAYERS.ASSET_ID);
    map.on('click', MAP_DEFAULTS.LAYERS.ASSET_ID.id, ({ features }) => {
      const feature = features![0] as unknown as IMonitorDataFeature;
      window.open(feature.properties.href);
    });
  }
  */
};
