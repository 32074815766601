import React, { Reducer, useEffect } from 'react';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';

import { IAction, Variant } from '../../common/types';
import { useDispatch, useState } from './common';
import { useNotifications } from './notifications';

export const APITokenAuthContext = React.createContext('');

// Slice_name
export const sliceName = 'auth';

// Types & Interfaces
export enum AuthProvider {
  'AUTH0' = 'auth0',
  'GOOGLE_OAUTH2' = 'google-oauth2',
}

export enum AuthRole {
  NONE = '',
  ADMIN = 'Admin',
  SUPER_ADMIN = 'Super Admin',
}

export interface IAuthUser {
  role: AuthRole;
  picture: string;
  name: string;
  email: string;
  nickname: string;
  relatedChannelPartner?: number;
  relatedEntity?: number;
}

export interface IAuth {
  idToken?: String;
  user?: IAuthUser;
}

// Initial state
export var initialState: IAuth = {
  idToken: undefined,
  user: undefined,
};

// Actions
const ID_TOKEN_FETCHED = `${sliceName}/ID_TOKEN_FETCHED`;
const API_TOKEN_FETCHED = `${sliceName}/API_TOKEN_FETCHED`;
const USER_FETCHED = `${sliceName}/USER_FETCHED`;

// Slice reducers
const reducer: Reducer<IAuth, IAction> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ID_TOKEN_FETCHED:
      return {
        ...state,
        idToken: payload,
      };
    case API_TOKEN_FETCHED:
      return {
        ...state,
        apiToken: payload,
      };
    case USER_FETCHED:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};

// IAction creators
export const idTokenFetched = (idToken: string) => {
  return { type: ID_TOKEN_FETCHED, payload: idToken };
};

export const apiTokenFetched = (apiToken: string) => {
  return { type: API_TOKEN_FETCHED, payload: apiToken };
};

export const userFetched = (user: IAuthUser) => {
  return { type: USER_FETCHED, payload: user };
};

// API Selectors Hooks
export const useAuth = () => {
  const dispatch = useDispatch();
  const { user, ...auth0state } = useAuth0(); // Exclude auth0 user from the public API

  // Hook to listen for auth0 user changes / update context state with data from auth0
  useEffect(() => {
    if (user) {
      const userData = {
        ...user,
      };

      // @ts-ignore
      dispatch(userFetched(userData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    ...auth0state,
    ...useState()[sliceName],
  };
};

export const useIDToken = () => useAuth().idToken;
export const useUser = () => useAuth().user;

// API Actions Hooks
export function useGetIDToken() {
  const dispatch = useDispatch();
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const { send: sendNotification } = useNotifications();
  return async () => {
    try {
      await getAccessTokenSilently();
      const claims = await getIdTokenClaims();
      const idToken = claims?.__raw;
      if (idToken) {
        dispatch(idTokenFetched(idToken));
      } else {
        throw new Error('idToken undefined or null.');
      }
    } catch (error) {
      sendNotification({
        message: 'Failed to fetch idToken!',
        variant: Variant.DANGER,
        metadata: error,
      });
    }
  };
}

// API Components Helper Hooks
export function useCallOnIsAuthenticated(cb: () => void, deps: any[] = []) {
  const { isAuthenticated, isLoading } = useAuth0();
  useEffect((): void => {
    if (!isLoading && isAuthenticated) {
      cb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, ...deps]);
}

export function useCallOnNotAuthenticated(cb: () => void, deps: any[] = []) {
  const { isAuthenticated, isLoading } = useAuth0();
  useEffect((): void => {
    if (!isLoading && !isAuthenticated) {
      cb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, ...deps]);
}

// API HOCs
export const AuthContextProvider = Auth0Provider;

export default reducer;
