import createSlice from './helpers/createSlice';

// Types & Interfaces
export interface IExceptionReportLimit {
  relatedSiteMonitorClass: number;
  monitorPoint: string;
  assetType: string;
  lowerLimit: number;
  lowerLimitColour: string;
  upperLimit: number;
  upperLimitColour: string;
}

export interface IContact {
  email?: string;
}

export interface IExceptionReportLimits {
  limits: IExceptionReportLimit[];
  contacts?: IContact[];
}

// Slice Name
export const sliceName = 'exceptionReportLimits';
export const url = 'exception-report-limits';

// Abstract Slice Instance
const exceptionReportLimitsSlice = createSlice<IExceptionReportLimits>(
  sliceName,
  url
);

// Initial state
export const initialState = exceptionReportLimitsSlice.initialState;

// Slice Selectors Hooks
export const useExceptionReportLimits = exceptionReportLimitsSlice.useSlice;

// Slice Actions Hooks
export const useLoadExceptionReportLimits =
  exceptionReportLimitsSlice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors =
  exceptionReportLimitsSlice.useDismissHTTPErrors;

// Slice reducer
export default exceptionReportLimitsSlice.reducer;
