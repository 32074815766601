import createSlice, { ISliceState } from './helpers/createSlice';
import { createSimpleLoadHook } from '../../utils/hooks';

// Types & Interfaces
export interface IVersion extends ISliceState<{ version: string }> {
  version: string;
}

// Slice Name
export const sliceName = 'version';
export const url = `/${sliceName}`;

// Abstract Slice Instance
const versionSlice = createSlice<IVersion>(sliceName, url, undefined);

// Initial state
export const initialState = versionSlice.initialState;

// Slice Selectors Hooks
export const useVersion = versionSlice.useSlice;

// Slice Actions Hooks
export const useLoadVersion = versionSlice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors = versionSlice.useDismissHTTPErrors;

export const useVersionSimple = createSimpleLoadHook(
  useVersion,
  useLoadVersion
);

// Slice reducer
export default versionSlice.reducer;
