import { keyBy, sortBy, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  IMonitorDataQuery,
  useLoadMonitorData,
  useMonitorData,
} from '../../state/modules/monitorData';
import {
  useMonitorDataFields,
  useLoadMonitorDataFields,
  isMonitorDataValueField,
} from '../../state/modules/monitorDataFields';
import {
  useMonitorDataListing,
  useLoadMonitorDataListing,
} from '../../state/modules/monitorDataListing';
import { getCenter } from '../helpers/coordinate';
import { useMonitorDataFilter, pickGeoData } from './Filter';

const useMonitorDataAndFields = () => {
  const {
    payload: monitorData,
    status: monitorDataStatus,
    httpErrors: monitorDataErrors,
  } = useMonitorData();
  const loadMonitorDataRef = useRef(useLoadMonitorData());

  const loadMonitorData = useCallback<typeof loadMonitorDataRef.current>(
    (query) => loadMonitorDataRef.current(query),
    []
  );

  const {
    payload: allFields,
    status: fieldsStatus,
    httpErrors: fieldsErrors,
  } = useMonitorDataFields();
  const loadMonitorDataFields = useRef(useLoadMonitorDataFields());
  useEffect(() => {
    loadMonitorDataFields.current({ includeCalculatedFields: true });
  }, []);

  const {
    payload: listing,
    status: listingStatus,
    httpErrors: listingErrors,
  } = useMonitorDataListing();
  const loadMonitorDataListing = useRef(useLoadMonitorDataListing());
  useEffect(() => {
    loadMonitorDataListing.current({});
  }, []);

  const fieldsById = useMemo(
    () =>
      allFields?.length
        ? keyBy(allFields.filter(isMonitorDataValueField), 'id')
        : {},
    [allFields]
  );
  const fields = useMemo(
    () => (fieldsById ? sortBy(Object.values(fieldsById), 'order') : undefined),
    [fieldsById]
  );

  return {
    monitorData,
    monitorDataStatus,
    loadMonitorData,
    fields,
    fieldsById,
    fieldsStatus,
    listing,
    listingStatus,
    errors: [monitorDataErrors, fieldsErrors, listingErrors],
  };
};

export const useMonitorDataProcess = () => {
  const {
    monitorData,
    loadMonitorData,
    fields,
    fieldsById,
    ...passThroughMonitorDataAndFields
  } = useMonitorDataAndFields();

  const { data, siteBoundary } = monitorData || {};

  const { sections } = useMemo(() => {
    if (!data) {
      return { sections: [] as string[] };
    }

    const sections = uniq(
      data.features.map(({ properties: { section } }) => section)
    ).filter((v) => !!v) as string[];

    return { sections };
  }, [data]);

  const { filter, setFilter, commitFilter } = useMonitorDataFilter();

  const { section, fieldId, min, max } = filter || {};

  const selectedField = useMemo(
    () =>
      fieldId !== undefined && fieldsById ? fieldsById[fieldId] : undefined,
    [fieldId, fieldsById]
  );

  const geoData = useMemo(
    () =>
      data && fieldId !== undefined
        ? pickGeoData(data, { section, fieldId })
        : undefined,
    [data, section, fieldId]
  );

  const geoDataCenter = useMemo(
    () => (data ? getCenter(data) : undefined),
    [data]
  );

  const { geoDataRange, geoDataAverage } = useMemo(() => {
    if (!geoData || geoData.features.length === 0) return {};
    const values = [...geoData.features, ...(siteBoundary?.features || [])].map(
      ({ properties: { value } }) => value
    );
    return {
      geoDataRange: [Math.min(...values), Math.max(...values)] as const,
      geoDataAverage: values.reduce((a, b) => a + b, 0) / values.length,
    };
  }, [geoData, siteBoundary]);

  const selectedFieldFormatValue = useMemo(
    () =>
      geoDataRange && selectedField
        ? (value: number) => {
            const fieldValue =
              geoDataRange[0] + value * (geoDataRange[1] - geoDataRange[0]);
            const { properties: { decimalPlaces = 2 } = {} } = selectedField;

            return fieldValue.toFixed(decimalPlaces);
          }
        : undefined,
    [geoDataRange, selectedField]
  );

  const handleQuery = useMemo(
    () =>
      fields?.length
        ? (query: IMonitorDataQuery) => {
            commitFilter();
            loadMonitorData({
              ...query,
              fieldIds: sortBy(fields.map(({ id }) => id)).join(','),
            });
          }
        : undefined,
    [fields, commitFilter, loadMonitorData]
  );

  const selectedRange = useMemo(() => [min, max] as const, [min, max]);
  const handleSelectedRangeChange = useCallback(
    ([min, max]: readonly [number?, number?]) =>
      setFilter({ ...filter, min, max }),
    [filter, setFilter]
  );
  const { selectedRangeNormalized, handleSelectedRangeNormalizedChange } =
    useRangeNormalized(geoDataRange, selectedRange, handleSelectedRangeChange);

  return {
    geoData,
    geoDataRange,
    geoDataAverage,
    geoDataCenter,
    siteBoundary,
    sections,
    fields,
    fieldsById,
    selectedField,
    selectedFieldFormatValue,
    filter,
    setFilter,
    commitFilter,
    handleQuery,
    handleSelectedRangeChange,
    selectedRangeNormalized,
    handleSelectedRangeNormalizedChange,
    ...passThroughMonitorDataAndFields,
  };
};

const normalizeValueInRange = (
  defaultValue: number,
  range?: readonly [number, number],
  value?: number
) =>
  range === undefined || value === undefined
    ? defaultValue
    : Math.max(0, Math.min((value - range[0]) / (range[1] - range[0]), 1));

export const useRangeNormalized = (
  range?: readonly [number, number],
  selectedRange?: readonly [number?, number?],
  change?: (newSelectedRange: [number?, number?]) => void
) => {
  const selectedRangeNormalized = useMemo(
    () =>
      range
        ? ([
            normalizeValueInRange(0, range, selectedRange?.[0]),
            normalizeValueInRange(1, range, selectedRange?.[1]),
          ] as const)
        : ([0, 1] as const),
    [range, selectedRange]
  );

  const handleSelectedRangeNormalizedChange = useMemo(
    () =>
      range && change
        ? ([min, max]: readonly [number?, number?]) => {
            change([
              min === 0 || min === undefined
                ? undefined
                : range[0] + (min || 0) * (range[1] - range[0]),
              max === 1 || max === undefined
                ? undefined
                : range[0] + (max || 1) * (range[1] - range[0]),
            ]);
          }
        : undefined,
    [range, change]
  );

  return {
    selectedRangeNormalized,
    handleSelectedRangeNormalizedChange,
  };
};
