import { useDispatch, useApiWithToken } from './common';
import createSlice from './helpers/createSlice';
import { IAPIErrorFormat } from '../../common/types';

// Types & Interfaces
export interface IMonitorDataQuery {
  readonly siteId: number;
  readonly siteMonitorClassId: number;
  /** ISO 8601 */
  readonly startDate?: string;
  /** ISO 8601 */
  readonly endDate?: string;
}

export interface IMonitorDataFeatureProperties {
  section?: string;
  values: Record<string, number>;
  href: string;
  assetId: number;
  monitorId: string;
  mapId: string;
}

export type IMonitorDataFeature = GeoJSON.Feature<
  GeoJSON.Point,
  IMonitorDataFeatureProperties
>;

export interface ISiteBoundaryFeatureProperties {
  value: number;
}

export type ISiteBoundaryFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.Point,
  ISiteBoundaryFeatureProperties
>;

export interface IMonitorData {
  data?: GeoJSON.FeatureCollection<
    GeoJSON.Point,
    IMonitorDataFeatureProperties
  >;
  siteBoundary?: ISiteBoundaryFeatureCollection;
}

// Slice Name
export const sliceName = 'monitorData';
export const url = `/monitor-data`;

// Abstract Slice Instance
const monitorDataSlice = createSlice<IMonitorData>(sliceName, url, {});

// Initial state
export const initialState = monitorDataSlice.initialState;

// Slice Selectors Hooks
export const useMonitorData = monitorDataSlice.useSlice;

// Slice Actions Hooks
export const useLoadMonitorData = monitorDataSlice.useLoadSlicePayload;

type FieldLogElement = { label: string; value: string };
interface IImportMonitorDataBody {
  records: Record<number, any>[];
  monitorIds?: string[];
  relatedSiteMonitorClass: number;
  fieldLog?: FieldLogElement[];
}

// Slice Actions Hooks
export const useImportMonitorData = () => {
  const dispatch = useDispatch();
  const apiWithIDToken = useApiWithToken();
  return async (body: IImportMonitorDataBody) => {
    try {
      dispatch(monitorDataSlice.requestLoading());
      await apiWithIDToken.post(url, body);
      dispatch(monitorDataSlice.requestLoaded());
    } catch (error) {
      dispatch(monitorDataSlice.requestFailed(error as IAPIErrorFormat));
    }
  };
};

export const useDismissBusinessesHTTPErrors =
  monitorDataSlice.useDismissHTTPErrors;

export const useResetMonitorDataState = monitorDataSlice.useResetState;

// Slice reducer
export default monitorDataSlice.reducer;
