import { Alert } from 'react-bootstrap';
import { IAPIErrorFormat } from '../../common/types';
import { mergeArrays } from '../../utils/array';
import { isDefined } from '../../utils/typeGuards';

// Example errors = [[firstError, secondError], [], [thirdError]]
export function displayAlerts(...errors: any[][]) {
  return mergeArrays(...errors)
    .filter(isDefined) // Filter empty errors
    .map((error, key) => (
      <Alert key={key} variant={'danger'}>
        {String(error)}
      </Alert>
    ));
}

export const displayAPIErrors = (...errors: (IAPIErrorFormat | undefined)[]) =>
  displayAlerts(
    ...errors
      .filter((item) => !!item)
      .map(
        (httpErrors) =>
          httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
            ? httpErrors.errors // Display httpErrors
            : [httpErrors?.message] // Fall back to error.message if no errors[] provided
      )
  );
