export const MAP_DEFAULTS = {
  CENTER: [-96.9468, 43.376] as [number, number],
  ZOOM: 16,
  LAYERS: {
    ASSET_HEAT: {
      id: 'assets-heat',
      type: 'heatmap',
      source: 'assets',
      style: 'mapbox://styles/219digital/ckt8sfrtm0o0518pgyts8hf3a',
      paint: {
        'heatmap-weight': [
          'interpolate',
          ['linear'],
          ['get', 'value'],
          0,
          1,
          50,
          5,
        ],
      },
    } as mapboxgl.AnyLayer,

    ASSET_POINT: {
      id: 'assets-point',
      type: 'circle',
      source: 'assets',
      style:
        'https://api.mapbox.com/styles/v1/iangreig/ckv8d8c5y9gkf14mh80hsz4x7/wmts?access_token=pk.eyJ1IjoiaWFuZ3JlaWciLCJhIjoiY2o4b3ZkYWo1MDhqMDJxcnpiZGc0NnNydCJ9.tax_cke2JgCANmt5F7ACuQ',
      minzoom: 15,
    } as mapboxgl.AnyLayer,

    ASSET_LABEL: {
      id: 'assets-label',
      type: 'symbol',
      source: 'assets',
      layout: {
        'text-field': '{mapId}',
        'text-size': 10,
        'text-offset': [0, 2],
      },
      minzoom: 15,
    } as mapboxgl.AnyLayer,

    ASSET_ID: {
      id: 'assets-symbols',
      type: 'symbol',
      source: 'assets',
      minzoom: 18,
      layout: {
        'text-field': ['get', 'monitorId'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 0.8,
        'text-justify': 'auto',
      },
    } as mapboxgl.AnyLayer,
  },
};
