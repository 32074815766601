import React, {
  FunctionComponent,
  HTMLAttributes,
  useMemo,
  MouseEvent,
  ReactNode,
  useState,
  useCallback,
} from 'react';
import { CSSProperties } from 'styled-components';
import { gradientToCSSGradient, HeatMapGradient } from './Gradient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const snapDefault = 0.05;

interface Props extends HTMLAttributes<HTMLDivElement> {
  caption?: ReactNode;
  /** value: [0, 1] */
  formatValue?: (value: number) => ReactNode;
  gradient: HeatMapGradient;
  gradientStyle?: CSSProperties;
  selectedRange?: readonly [number?, number?];
  onSelectedRangeChange?: (selectedRange: readonly [number?, number?]) => void;
}

const getValueSanitized = (v: number) => Math.max(0, Math.min(v, 1));

const getValueSnap = (v: number, snap = snapDefault) =>
  v + snap >= 1 ? 1 : v - snap <= 0 ? 0 : v;

export const HeatMapLegend: FunctionComponent<Props> = (props) => {
  const {
    caption,
    formatValue,
    gradient,
    gradientStyle,
    selectedRange,
    onSelectedRangeChange,
    ...passThroughProps
  } = props;

  const [hoverValue, setHoverValue] = useState<number>();

  const [newSelectedRangeA, setNewSelectedRangeA] = useState<number>();

  const background = useMemo(() => gradientToCSSGradient(gradient), [gradient]);

  const handleMouseMove = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const band = e.currentTarget.getBoundingClientRect();
    setHoverValue(getValueSanitized((e.pageX - band.left) / band.width));
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoverValue(undefined);
  }, []);

  const handleMouseDown = useMemo(
    () =>
      onSelectedRangeChange
        ? (e: MouseEvent<HTMLDivElement>) => {
            const band = e.currentTarget.getBoundingClientRect();
            setNewSelectedRangeA(
              getValueSnap((e.pageX - band.left) / band.width)
            );
          }
        : undefined,
    [onSelectedRangeChange]
  );

  const preSelectedRange = useMemo(
    () =>
      newSelectedRangeA !== undefined && hoverValue !== undefined
        ? ([
            getValueSnap(Math.min(newSelectedRangeA, hoverValue)),
            getValueSnap(Math.max(newSelectedRangeA, hoverValue)),
          ] as const)
        : undefined,
    [newSelectedRangeA, hoverValue]
  );

  const handleMouseUp = useMemo(
    () =>
      onSelectedRangeChange && preSelectedRange
        ? (e: MouseEvent<HTMLDivElement>) => {
            setNewSelectedRangeA(undefined);
            onSelectedRangeChange(
              preSelectedRange[1] - preSelectedRange[0] < snapDefault
                ? [preSelectedRange[0], 1]
                : preSelectedRange
            );
          }
        : undefined,
    [onSelectedRangeChange, preSelectedRange]
  );

  const captionDisplay = caption && (
    <div style={{ padding: 4, display: 'flex', justifyContent: 'center' }}>
      {caption}
    </div>
  );

  const hoverValueDisplay = hoverValue !== undefined && formatValue && (
    <div
      style={{
        position: 'absolute',
        bottom: '100%',
        // Aligning right border to cursor on gradient
        right: `${(1 - hoverValue) * 100}%`,
        background: 'white',
      }}
    >
      {formatValue(hoverValue)}
    </div>
  );

  const hoverValueCursorDisplay = hoverValue !== undefined && (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: `${hoverValue * 100}%`,
        width: 2,
        background: 'green',
        borderLeft: '1px solid white',
      }}
    />
  );

  const selectedRangeCursorDisplay = preSelectedRange && (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: `${preSelectedRange[0] * 100}%`,
        width: `${(preSelectedRange[1] - preSelectedRange[0]) * 100}%`,
        background: 'white',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        opacity: 0.6,
      }}
    />
  );

  const rangeDisplay = formatValue && (
    <div
      style={{
        display: 'flex',
        color: 'rgb(80, 80, 80)',
        fontSize: 'small',
        padding: '0 2px',
      }}
    >
      <div>{formatValue(0)}</div>
      <div style={{ flex: 1, textAlign: 'center' }}>
        {selectedRange === undefined ? (
          onSelectedRangeChange && (
            <i style={{ color: 'lightgrey' }}>
              Select range on&nbsp;color&nbsp;band to&nbsp;filter
            </i>
          )
        ) : (
          <span className="media-print-display-none">
            <i>Selected:</i> {formatValue(selectedRange[0] || 0)} &mdash;{' '}
            {formatValue(selectedRange[1] || 1)}
            &nbsp;
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faTimes}
              onClick={() => onSelectedRangeChange?.([undefined, undefined])}
            />
          </span>
        )}
      </div>
      <div>{formatValue(1)}</div>
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        borderRadius: 4,
        background: 'white',
        ...passThroughProps.style,
      }}
      {...passThroughProps}
    >
      <div
        style={{
          position: 'relative',
          background,
          flex: 1,
          minHeight: '1em',
          userSelect: 'none',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          ...gradientStyle,
        }}
        className="media-print-color-exact"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {selectedRangeCursorDisplay}
        {hoverValueCursorDisplay}
        {hoverValueDisplay}
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            userSelect: 'none',
            zIndex: 1,
          }}
        />
      </div>
      {rangeDisplay}
      {captionDisplay}
    </div>
  );
};
