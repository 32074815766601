import { Component, ErrorInfo } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';

interface IErrorBoundaryProps {}
interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(`Rendering children failed with ${error}.`);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Row>
            <Col>
              <Alert variant="danger">
                An error occurred on import. Please reload the page. Check your
                header column, as an incorrect assignment can stop parsing.
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
