import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { ImportSpreadsheetForm } from '../components/forms/ImportSpreadsheetForm';
import MonitorDataConfirmationScreen from '../components/MonitorDataConfirmationScreen/MonitorDataConfirmationScreen';
import MonitorDataTable from '../components/MonitorDataTable';
import { ICustomer } from '../state/modules/customers';
import { ISiteMonitorClass } from '../state/modules/siteMonitorClasses';
import { ISite } from '../state/modules/sites';
import { isDefined } from '../utils/typeGuards';
import ErrorBoundary from './ErrorBoundary';

export interface ISpreadsheetFormData {
  customer: ICustomer;
  site: ISite;
  siteMonitorClass: ISiteMonitorClass;
  headerRow: number;
  file: File;
}

export type ISpreadsheetMappedData = Record<number, any>[];

function Home() {
  const [spreadsheetFormData, setSpreadsheetFormData] =
    useState<ISpreadsheetFormData>();

  const [spreadsheetMappedData, setSpreadsheetMappedData] =
    useState<ISpreadsheetMappedData>();

  function handleSpreadsheetFormSubmit(data: ISpreadsheetFormData) {
    setSpreadsheetFormData(data);
  }

  function saveSpreadSheetMappedData(data: ISpreadsheetMappedData) {
    setSpreadsheetMappedData(data);
  }

  function handleCancel() {
    window.location.reload();
  }

  function handleMonitorDataImport() {
    window.location.reload();
  }

  return (
    <ErrorBoundary>
      <Container fluid data-test-id="Home">
        <Container>
          {isDefined(spreadsheetFormData) ? (
            isDefined(spreadsheetMappedData) ? (
              <MonitorDataConfirmationScreen
                data={spreadsheetMappedData}
                siteMonitorClass={spreadsheetFormData.siteMonitorClass}
                onCancel={handleCancel}
                onImport={handleMonitorDataImport}
              />
            ) : (
              <MonitorDataTable
                spreadsheetFormData={spreadsheetFormData!}
                onSubmit={saveSpreadSheetMappedData}
              />
            )
          ) : (
            <ImportSpreadsheetForm onSubmit={handleSpreadsheetFormSubmit} />
          )}
        </Container>
      </Container>
    </ErrorBoundary>
  );
}

export default Home;
