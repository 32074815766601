export function deepCloneArray<T>(data: T[]) {
  const m: T[] = [];
  data.forEach((element) => m.push({ ...element }));
  return m;
}

export function mergeArrays<T extends any>(...args: T[][]) {
  return args.reduce((prev, cur) => [...prev, ...cur], []);
}

function selectByPropertyFactory(comparison: (a: any, b: any) => boolean) {
  return <T extends Record<string, any>>(collection: T[], property: string) =>
    collection.reduce((acc: T | undefined, item: T) => {
      if (acc === undefined || comparison(item[property], acc[property])) {
        return item;
      } else {
        return acc;
      }
    }, undefined);
}

export const maxByProperty = selectByPropertyFactory(
  (a: number, b: number) => a > b
);

export const minByProperty = selectByPropertyFactory(
  (a: number, b: number) => a < b
);
