import createSlice from './helpers/createSlice';
import { ISiteMonitorClass } from './siteMonitorClasses';

const siteMonitorClassListingFields = [
  'recordId',
  'siteName',
  'relatedSite',
  'monitorClass',
  'relatedMonitorClass',
  'includeForHeatMaps',
] as const;
type ISiteMonitorClassItem = Pick<
  ISiteMonitorClass,
  typeof siteMonitorClassListingFields[number]
>;

export interface MonitorDataListing {
  siteMonitorClasses?: ISiteMonitorClassItem[];
}

// Slice Name
export const sliceName = 'monitorDataListing';
export const url = `/monitor-data/listing`;

// Abstract Slice Instance
const slice = createSlice<MonitorDataListing>(sliceName, url, {});

export const initialState = slice.initialState;

export const useMonitorDataListing = slice.useSlice;

export const useLoadMonitorDataListing = slice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors = slice.useDismissHTTPErrors;

// Slice reducer
export default slice.reducer;
