import confirmationReducer, {
  sliceName as confirmationSliceName,
} from './modules/confirmation';
import notificationsReducer, {
  sliceName as notificationsSliceName,
} from './modules/notifications';
import authReducer, { sliceName as authSliceName } from './modules/auth';
import versionReducer, {
  sliceName as versionSliceName,
} from './modules/version';
import monitorDataReducer, {
  sliceName as monitorDataSliceName,
} from './modules/monitorData';
import monitorDataImportJobsReducer, {
  sliceName as monitorDataImportJobsSliceName,
} from './modules/monitorDataImportJobs';
import monitorDataListingReducer, {
  sliceName as monitorDataListingSliceName,
} from './modules/monitorDataListing';
import customersReducer, {
  sliceName as customersSliceName,
} from './modules/customers';
import sitesReducer, { sliceName as sitesSliceName } from './modules/sites';
import siteMonitorClassesReducer, {
  sliceName as siteMonitorClassesSliceName,
} from './modules/siteMonitorClasses';
import monitorDataFieldsReducer, {
  sliceName as monitorDataFieldsSliceName,
} from './modules/monitorDataFields';
import assetsReducer, { sliceName as assetsSliceName } from './modules/assets';
import exceptionReportLimitsReducer, {
  sliceName as exceptionReportLimitsSliceName,
} from './modules/exceptionReportLimits';

import { IState } from './initialState';
import { combineReducers } from './modules/common';

const reducer = combineReducers<IState>({
  [confirmationSliceName]: confirmationReducer,
  [notificationsSliceName]: notificationsReducer,
  [authSliceName]: authReducer,
  [versionSliceName]: versionReducer,
  [monitorDataSliceName]: monitorDataReducer,
  [monitorDataImportJobsSliceName]: monitorDataImportJobsReducer,
  [monitorDataListingSliceName]: monitorDataListingReducer,
  [customersSliceName]: customersReducer,
  [sitesSliceName]: sitesReducer,
  [siteMonitorClassesSliceName]: siteMonitorClassesReducer,
  [monitorDataFieldsSliceName]: monitorDataFieldsReducer,
  [assetsSliceName]: assetsReducer,
  [exceptionReportLimitsSliceName]: exceptionReportLimitsReducer,
});

export default reducer;
