import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import {
  fieldType,
  IDataField,
  RequestStatus,
  Variant,
} from '../../common/types';
import {
  useLoadMonitorDataFields,
  useMonitorDataFields,
} from '../../state/modules/monitorDataFields';
import { isUndefined } from '../../utils/typeGuards';
import { ScreenLoader } from '../common/Loaders';
import { displayAlerts } from './displayAlerts';
import { ISpreadsheetDataRows, ISpreadsheetHeaders } from './Spreadsheet';
import styled from 'styled-components';

interface IFieldMapModalPropTypes {
  onConfirm: (field: IDataField, cb?: (value: any) => any) => void;
  onCancel?: () => void;
  spreadsheetHeaders: ISpreadsheetHeaders;
  spreadsheetDataRows: ISpreadsheetDataRows;
  selectedHeaderToMap: number;
  blockedFields: IDataField['id'][];
}

export const TARGET_DATE_FORMAT = 'DD MMM, YYYY, HH:mm:ss';

const ISO_DATE_FORMAT = '';
const OTHER_DATE_FORMAT = 'Other';

export const DATE_FORMATS = [
  'DD/MM/YYYY HH:mm',
  'DD-MM-YYYY HH:mm',
  ISO_DATE_FORMAT,
  TARGET_DATE_FORMAT,
  'dddd, MMMM Do YYYY, h:mm:ss a',
  'MMM Do, YYYY HH:mm',
  OTHER_DATE_FORMAT,
];

function FieldMapModal({
  onConfirm,
  onCancel,
  spreadsheetHeaders,
  spreadsheetDataRows,
  selectedHeaderToMap,
  blockedFields,
}: IFieldMapModalPropTypes) {
  const [selectedField, setSelectedField] = useState<number | undefined>(
    spreadsheetHeaders[selectedHeaderToMap][2]?.id // auto populate selectedField when persist on current selected header
  );
  const [showDateControls, setShowDateControls] = useState(false);
  const [selectedDateFormat, setSelectedDateFormat] = useState(DATE_FORMATS[0]);
  const [dateFormatTextInputEnabled, setDateFormatTextInputEnabled] =
    useState(false);

  const monitorDataFields = useMonitorDataFields();
  const loadMonitorDataFields = useLoadMonitorDataFields();

  useEffect(() => {
    if (monitorDataFields.status === RequestStatus.IDLE) {
      loadMonitorDataFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorDataFields]);

  useEffect(() => {
    if (selectedField && spreadsheetDataRows && monitorDataFields) {
      const field = monitorDataFields.payload.find(
        (field) => field.id === selectedField
      );

      const isDateField =
        field?.fieldType === ('timestamp' as fieldType) ||
        field?.fieldType === ('date' as fieldType);

      setShowDateControls(isDateField);

      // The following block is to auto detect a date format
      if (isDateField) {
        const text = spreadsheetDataRows[0][selectedHeaderToMap];
        for (let i = 0; i < DATE_FORMATS.length; i++) {
          const format = DATE_FORMATS[i];
          if (moment(text, format).isValid()) {
            setSelectedDateFormat(format);
            break;
          }
        }
      }
    }
  }, [
    selectedField,
    spreadsheetDataRows,
    monitorDataFields,
    selectedHeaderToMap,
  ]);

  function handleSelectedFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = +e.target.value;
    setSelectedField(isNaN(value) ? undefined : value);
  }

  function handleSelectedDateFormatChange(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (e.target.value === DATE_FORMATS[DATE_FORMATS.length - 1]) {
      setDateFormatTextInputEnabled(true);
    } else {
      setSelectedDateFormat(e.target.value);
    }
  }

  function handleSubmit() {
    if (selectedField && monitorDataFields) {
      const field = monitorDataFields.payload.find(
        (field) => field.id === selectedField
      );
      const cb = showDateControls
        ? (text: string) =>
            moment(text, selectedDateFormat).format(TARGET_DATE_FORMAT)
        : undefined;
      onConfirm(field!, cb);
    }
  }

  const fieldsOptions = useMemo(() => {
    return [
      <option key="">Select Field</option>,
      ...monitorDataFields.payload
        .sort((a, b) => (a.required ? -1 : a.label < b.label ? -1 : 1))
        .filter((field) => !~blockedFields.indexOf(field.id))
        .map((field) => (
          <option key={field.id} value={field.id}>
            {`${field.label}${field.required ? ' (required)' : ''}`}
          </option>
        )),
    ];
  }, [monitorDataFields.payload, blockedFields]);

  const dateFormatOptions = useMemo(() => {
    return [
      ...DATE_FORMATS.map((format, index) => (
        <option key={index} value={format}>
          {format === OTHER_DATE_FORMAT
            ? OTHER_DATE_FORMAT
            : moment().format(format)}
        </option>
      )),
    ];
  }, []);

  const sampleDatesRows = useMemo(() => {
    return spreadsheetDataRows.slice(0, 5).map((row, index) => {
      const date = row[selectedHeaderToMap];
      return (
        <tr key={index}>
          <td>{date}</td>
          <td>{moment(date, selectedDateFormat).format(TARGET_DATE_FORMAT)}</td>
        </tr>
      );
    });
  }, [spreadsheetDataRows, selectedHeaderToMap, selectedDateFormat]);

  const hasFutureDates = useMemo(() => {
    for (let i = 0; i < spreadsheetDataRows.length; i++) {
      const row = spreadsheetDataRows[i];
      const date = row[selectedHeaderToMap];
      if (moment(date, selectedDateFormat) > moment()) {
        return true;
      }
    }
    return false;
  }, [spreadsheetDataRows, selectedHeaderToMap, selectedDateFormat]);

  const httpErrors = monitorDataFields.httpErrors;

  return (
    <Modal onHide={onCancel} animation={false} show={true} centered>
      <Modal.Header>
        <h4 className="mx-auto">Map to field</h4>
      </Modal.Header>
      <Modal.Body>
        {displayAlerts(
          httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
            ? httpErrors.errors // Display httpErrors
            : [httpErrors?.message] // Fall back to error.message if no errors[] provided
        )}
        <Form.Group className="mb-3">
          <Form.Label>Select a field to map to</Form.Label>
          {monitorDataFields.status === RequestStatus.LOADING ? (
            <ScreenLoader />
          ) : (
            <Form.Control
              name="field"
              autoComplete="off"
              as="select"
              value={selectedField}
              onChange={handleSelectedFieldChange}
              data-test-id="select-map-to-field"
            >
              {fieldsOptions}
            </Form.Control>
          )}
        </Form.Group>
        {showDateControls && (
          <Form.Group className="mb-3 mt-3">
            <Row>
              <Col xs={8}>
                {dateFormatTextInputEnabled ? (
                  <Form.Control
                    name="dateFormat"
                    type="text"
                    value={selectedDateFormat}
                    onChange={handleSelectedDateFormatChange}
                  />
                ) : (
                  <Form.Control
                    name="dateFormat"
                    as={'select'}
                    value={selectedDateFormat}
                    onChange={handleSelectedDateFormatChange}
                  >
                    {dateFormatOptions}
                  </Form.Control>
                )}
              </Col>
              <Col xs={4} className="mt-2">
                <a
                  href="https://momentjs.com/docs/#/displaying/format/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Date Formats
                </a>
              </Col>
            </Row>
            <Form.Text className="text-muted mt-3 mb-3 text-center">
              The first five dates of your dataset will be read as follows:
            </Form.Text>
            {hasFutureDates && (
              <Alert variant={Variant.DANGER}>
                Warning: Future dates detected.
              </Alert>
            )}
            <Table striped>
              <thead>
                <tr>
                  <th>Before</th>
                  <th>After</th>
                </tr>
              </thead>
              <tbody>{sampleDatesRows}</tbody>
            </Table>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={Variant.PRIMARY}
          onClick={handleSubmit}
          disabled={isUndefined(selectedField)}
          data-test-id="confirm-map-to-field"
        >
          Confirm
        </Button>
        <Button variant={Variant.LIGHT} onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default styled(FieldMapModal)``;
