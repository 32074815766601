import { useApiWithToken, useDispatch } from '../common';
import createSlice from '../helpers/createSlice';
import { IAPIErrorFormat } from '../../../common/types';

// Types & Interfaces
export type IValidateAssetsResponse = {
  monitorId: string;
  valid: boolean;
  relatedAsset?: number;
  assetType?: string;
}[];
export type IValidateAssetsBody = {
  assets: string[];
  relatedSiteMonitorClass: number;
};

// Slice Name
export const parentSliceName = 'assets';
export const sliceName = 'validate';
export const url = 'assets/validate';

// Abstract Slice Instance
const validateAssetsSlice = createSlice<IValidateAssetsResponse>(
  sliceName,
  url,
  undefined,
  parentSliceName
);

// Initial state
export const initialState = validateAssetsSlice.initialState;

// Slice Selectors Hooks
export const useValidateAssetsState = validateAssetsSlice.useSlice;

// Slice Actions Hooks
export const useValidateAssets = () => {
  const dispatch = useDispatch();
  const apiWithIDToken = useApiWithToken();
  return async (body: IValidateAssetsBody) => {
    try {
      dispatch(validateAssetsSlice.requestLoading());
      const { data } = await apiWithIDToken.post(url, body);
      dispatch(validateAssetsSlice.requestLoaded(data));
    } catch (error: any) {
      dispatch(validateAssetsSlice.requestFailed(error as IAPIErrorFormat));
    }
  };
};

// Slice reducer
export default validateAssetsSlice.reducer;
