import { orderBy } from 'lodash';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Form } from 'react-bootstrap';
import { IDataField } from '../../common/types';
import { IMonitorDataQuery } from '../../state/modules/monitorData';
import { MonitorDataListing } from '../../state/modules/monitorDataListing';
import { IMonitorDataFilter, mapStyles } from './Filter';
import { MonitorDataQueryEditor } from './QueryEditor';

interface Props {
  filter: IMonitorDataFilter;
  sections?: readonly string[];
  fields?: readonly IDataField[];
  listing?: MonitorDataListing;
  onFilterChange: (newFilter: IMonitorDataFilter) => void;
  onQuery?: (query: IMonitorDataQuery) => void;
}

const getMonitorDataQuery = (
  { siteMonitorClassId, startDate, endDate }: IMonitorDataFilter,
  siteId: number | undefined | null
) =>
  siteMonitorClassId === undefined || typeof siteId !== 'number'
    ? undefined
    : {
        siteId,
        siteMonitorClassId,
        startDate,
        endDate,
      };

export const HeatMapControls: FunctionComponent<Props> = (props) => {
  const { filter, sections, fields, listing, onFilterChange, onQuery } = props;

  const { siteMonitorClassId, fieldId, section, min, max, style } = filter;

  const handleChange = useCallback(
    (fieldName: keyof IMonitorDataFilter) =>
      (newValue: IMonitorDataFilter[typeof fieldName]) =>
        onFilterChange({
          ...filter,
          [fieldName]: newValue,
        }),
    [filter, onFilterChange]
  );

  useEffect(() => {
    if (section && sections?.length && !sections.includes(section)) {
      handleChange('section')(undefined);
    }
  }, [section, sections, handleChange]);

  const [siteId, setSiteId] = useState<number | null>();

  const query = useMemo(
    () => getMonitorDataQuery(filter, siteId),
    [filter, siteId]
  );
  const handleLoad = useMemo(
    () =>
      query && onQuery
        ? () => {
            onQuery(query);
          }
        : undefined,
    [query, onQuery]
  );

  const [autoSetField, setAutoSetField] = useState(fieldId === undefined);
  useEffect(() => {
    if (autoSetField && fields?.length && fieldId === undefined) {
      setAutoSetField(false);
      handleChange('fieldId')(fields[0].id);
    }
  }, [autoSetField, fields, fieldId, handleChange]);

  const [autoLoad, setAutoLoad] = useState(siteMonitorClassId !== undefined);
  useEffect(() => {
    if (autoLoad && handleLoad) {
      setAutoLoad(false);
      handleLoad();
    }
  }, [autoLoad, handleLoad]);

  const fieldsOrdered = useMemo(() => orderBy(fields, 'order'), [fields]);

  return (
    <Form>
      <div style={{ padding: 12 }}>
        <MonitorDataQueryEditor
          siteId={siteId}
          onSiteIdChange={setSiteId}
          filter={filter}
          onFilterChange={onFilterChange}
          listing={listing}
        />

        <Form.Group>
          <Form.Label>Monitor&nbsp;point:</Form.Label>
          &nbsp;
          <Form.Control
            as="select"
            value={typeof fieldId === 'number' ? fieldId : ''}
            onChange={(e) =>
              handleChange('fieldId')(
                parseInt(e.currentTarget.value, 10) || undefined
              )
            }
          >
            <option key="empty" value={''}></option>
            {fieldsOrdered?.map(({ label, id }, index) => (
              <option key={`${index}-${id}`} value={id}>
                {label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Section:</Form.Label>
          &nbsp;
          <Form.Control
            as="select"
            value={section || ''}
            onChange={(e) =>
              handleChange('section')(e.currentTarget.value || undefined)
            }
          >
            <option
              key="empty"
              value={''}
              className="text-secondary font-italic"
            >
              All
            </option>
            {sections?.map((section, index) => (
              <option key={`section-${section}`} value={section}>
                {section}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <div style={{ display: 'flex' }}>
          <Form.Group>
            <Form.Label>Min:</Form.Label>
            &nbsp;
            <Form.Control
              type="number"
              value={min === undefined ? '' : Math.round(min)}
              onChange={(e) =>
                handleChange('min')(
                  parseInt(e.currentTarget.value, 10) || undefined
                )
              }
            />
          </Form.Group>
          &nbsp;
          <Form.Group>
            <Form.Label>Max:</Form.Label>
            &nbsp;
            <Form.Control
              type="number"
              value={max === undefined ? '' : Math.round(max)}
              onChange={(e) =>
                handleChange('max')(
                  parseInt(e.currentTarget.value, 10) || undefined
                )
              }
            />
          </Form.Group>
        </div>

        <Form.Group>
          <Form.Label>Map&nbsp;style:</Form.Label>
          &nbsp;
          <Form.Control
            as="select"
            value={style || ''}
            onChange={(e) =>
              handleChange('style')(e.currentTarget.value || undefined)
            }
          >
            <option
              key="empty"
              value={''}
              className="text-secondary font-italic"
            >
              Default
            </option>
            {Object.keys(mapStyles).map((style) => (
              <option key={`style-${style}`} value={style}>
                {style}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>

      <div
        style={{
          padding: 12,
          position: 'sticky',
          bottom: 0,
          background: 'white',
          paddingTop: 0,
          boxShadow: '0px -12px 5px -2px #FFFFFF',
        }}
      >
        <Button onClick={handleLoad} disabled={!handleLoad} block={true}>
          Load
        </Button>
      </div>
    </Form>
  );
};
