import { IDataField } from '../../common/types';
import createSlice from './helpers/createSlice';

export const isMonitorDataValueField = (field: IDataField) =>
  field.fieldType === 'numeric';

// Slice Name
export const sliceName = 'monitorDataFields';
export const url = `/monitor-data/fields`;

// Abstract Slice Instance
const monitorDataFieldsSlice = createSlice<IDataField[]>(
  sliceName,
  url,
  undefined
);

// Initial state
export const initialState = monitorDataFieldsSlice.initialState;

// Slice Selectors Hooks
export const useMonitorDataFields = monitorDataFieldsSlice.useSlice;

// Slice Actions Hooks
export const useLoadMonitorDataFields =
  monitorDataFieldsSlice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors =
  monitorDataFieldsSlice.useDismissHTTPErrors;

// Slice reducer
export default monitorDataFieldsSlice.reducer;
