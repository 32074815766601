import {
  initialState as notificationsInitialState,
  sliceName as notificationsSliceName,
} from './modules/notifications';
import {
  initialState as confirmationInitialState,
  sliceName as confirmationSliceName,
} from './modules/confirmation';
import {
  initialState as authInitialState,
  sliceName as authSliceName,
} from './modules/auth';
import {
  initialState as monitorDataInitialState,
  sliceName as monitorDataSliceName,
} from './modules/monitorData';
import {
  initialState as monitorDataImportJobsInitialState,
  sliceName as monitorDataImportJobsSliceName,
} from './modules/monitorDataImportJobs';
import {
  initialState as monitorDataListingInitialState,
  sliceName as monitorDataListingSliceName,
} from './modules/monitorDataListing';
import {
  initialState as customersInitialState,
  sliceName as customersSliceName,
} from './modules/customers';
import {
  initialState as sitesInitialState,
  sliceName as sitesSliceName,
} from './modules/sites';
import {
  initialState as siteMonitorClassesInitialState,
  sliceName as siteMonitorClassesSliceName,
} from './modules/siteMonitorClasses';
import {
  initialState as monitorDataFieldsInitialState,
  sliceName as monitorDataFieldsSliceName,
} from './modules/monitorDataFields';
import {
  initialState as assetsInitialState,
  sliceName as assetsSliceName,
} from './modules/assets';
import {
  initialState as exceptionReportLimitsInitialState,
  sliceName as exceptionReportLimitsSliceName,
} from './modules/exceptionReportLimits';

const initialState = {
  [confirmationSliceName]: confirmationInitialState,
  [notificationsSliceName]: notificationsInitialState,
  [authSliceName]: authInitialState,
  [monitorDataSliceName]: monitorDataInitialState,
  [monitorDataImportJobsSliceName]: monitorDataImportJobsInitialState,
  [monitorDataListingSliceName]: monitorDataListingInitialState,
  [customersSliceName]: customersInitialState,
  [sitesSliceName]: sitesInitialState,
  [siteMonitorClassesSliceName]: siteMonitorClassesInitialState,
  [monitorDataFieldsSliceName]: monitorDataFieldsInitialState,
  [assetsSliceName]: assetsInitialState,
  [exceptionReportLimitsSliceName]: exceptionReportLimitsInitialState,
};

export type IState = typeof initialState;

export default initialState;
