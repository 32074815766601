import createSlice from './helpers/createSlice';

// Types & Interfaces
export interface ISite {
  recordId: number;
  siteName: string;
  relatedCustomer: number;
}

// Slice Name
export const sliceName = 'sites';

// Abstract Slice Instance
const sitesSlice = createSlice<ISite[]>(sliceName);

// Initial state
export const initialState = sitesSlice.initialState;

// Slice Selectors Hooks
export const useSites = sitesSlice.useSlice;

// Slice Actions Hooks
export const useLoadSites = sitesSlice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors = sitesSlice.useDismissHTTPErrors;

// Slice reducer
export default sitesSlice.reducer;
