import React from 'react';
import { Container } from 'react-bootstrap';
import HeatMap from '../components/HeatMap';

function Map() {
  return (
    <Container fluid>
      <HeatMap />
    </Container>
  );
}

export default Map;
