import React, { useMemo } from 'react';
import { Form, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { IDataField, IStyledComponentPropTypes } from '../../common/types';

export type ISpreadsheetHeader = [
  label: string,
  enabled: boolean,
  matchField?: IDataField,
  cb?: (v: any) => any,
  overwriteOrder?: number
];
export type ISpreadsheetHeaders = ISpreadsheetHeader[];
export type ISpreadsheetDataRows = any[][];

export interface ISpreadsheetPropTypes extends IStyledComponentPropTypes {
  headers: ISpreadsheetHeaders;
  data: ISpreadsheetDataRows;
  onHeaderChange: (index: number, header: ISpreadsheetHeader) => void;
  onHeaderClick: (index: number) => void;
}

function Spreadsheet({
  headers,
  data,
  className,
  onHeaderChange,
  onHeaderClick,
}: ISpreadsheetPropTypes) {
  const headersRow = useMemo(
    () => (
      <tr>
        <th>#</th>
        {headers.map(([label, enabled, matchField], index) => {
          const id = `spreadsheet-headers-${label}`;
          const text = matchField ? `${label} (${matchField.label})` : label;
          return (
            <th
              className={`${enabled ? '' : 'disabled'} ${
                matchField ? ' column-match' : ''
              }`}
              key={index}
            >
              <Form.Group>
                <FontAwesomeIcon
                  icon={faEdit}
                  className={`overlay ${enabled ? '' : 'pe-none'}`}
                  onClick={() => enabled && onHeaderClick(index)}
                  data-test-id={`edit-${text}-field`}
                />
                <Form.Label htmlFor={id}>{text}</Form.Label>
                <Form.Check
                  type="checkbox"
                  id={id}
                  checked={enabled}
                  onChange={() => onHeaderChange(index, [label, !enabled])}
                  data-test-id={`toggle-${text}-field`}
                />
              </Form.Group>
            </th>
          );
        })}
      </tr>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headers]
  );
  const bodyRows = useMemo(
    () =>
      data.map((row, key) => (
        <tr key={key}>
          <td>{key + 1}</td>
          {row.map((value, index) => {
            const enabled = headers[index][1];
            const matchField = headers[index][2];
            const cb = headers[index][3];
            return (
              <td
                className={`${enabled ? '' : 'disabled'} ${
                  matchField ? ' column-match' : ''
                }`}
                key={index}
              >
                {cb ? cb(value) : value}
              </td>
            );
          })}
        </tr>
      )),
    [data, headers]
  );
  return (
    <Table
      bordered
      className={className}
      responsive
      data-test-id="match-columns-form"
    >
      <thead>{headersRow}</thead>
      <tbody>{bodyRows}</tbody>
    </Table>
  );
}

export default styled(Spreadsheet)`
  thead {
    position: sticky;
    background-color: white;
    top: -1px;
    z-index: 2;
  }
  th:first-child,
  td:first-child {
    position: sticky;
    left: -2px;
    background-color: white;
    z-index: 2;
  }
  td:first-child {
    z-index: 1;
  }
  th {
    position: relative;
    z-index: 0;
    white-space: nowrap;
  }
  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }
  .form-label {
    margin: 0;
    margin-left: 0.3rem;
  }
  .form-check {
    display: inline-block;
    transform: scale(1.3);
    margin-top: 2px;
    cursor: pointer;
    margin-left: 5rem;
  }
  .fa-edit {
    font-size: 1.2rem;
    cursor: pointer;
  }
  .disabled {
    background-color: #f0f2f4;
    color: #5d5e60;
  }
  .column-match {
    background: #60f41f;
  }

  .pe-none {
    cursor: auto;
  }
`;
