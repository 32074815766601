import { useHistory, useLocation } from 'react-router';

export const setUrlParams =
  (
    history: ReturnType<typeof useHistory>,
    location: ReturnType<typeof useLocation>
  ) =>
  (params: { readonly [name: string]: string | number | null | undefined }) => {
    const searchParams = new URLSearchParams(location.search);
    for (const [name, value] of Object.entries(params)) {
      if (value === null || value === undefined) searchParams.delete(name);
      else searchParams.set(name, value.toString());
    }

    const newUrl = window.location.pathname + '?' + searchParams.toString();

    history.push(newUrl);
  };
