//@TODO - Re-enanle max-lines https://app.clickup.com/t/2c1yvpp
/* eslint-disable max-lines*/
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FieldlogForm } from './FieldlogForm';
import {
  IStyledComponentPropTypes,
  RequestStatus,
  Variant,
} from '../../common/types';
import { ISpreadsheetMappedData } from '../../containers/Home';
import {
  useLoadMonitorDataFields,
  useMonitorDataFields,
} from '../../state/modules/monitorDataFields';
import {
  useValidateAssetsState,
  useValidateAssets,
} from '../../state/modules/assets/validateAssets';
import { isDefined } from '../../utils/typeGuards';
import { ScreenLoader } from '../common/Loaders';
import { ISiteMonitorClass } from '../../state/modules/siteMonitorClasses';
import {
  useExceptionReportLimits,
  useLoadExceptionReportLimits,
} from '../../state/modules/exceptionReportLimits';
import ExceptionReportLimitsTable from '../helpers/ExceptionReportLimitsTable';
import MappedDataTable, {
  IMissingAssetConfirmation,
} from '../helpers/MappedDataTable';
import {
  useImportMonitorData,
  useMonitorData,
  useResetMonitorDataState,
} from '../../state/modules/monitorData';
import { displayAlerts } from '../helpers/displayAlerts';
import {
  ASSET_INFO_FIELD,
  useExceptionReportLimitsGroups,
} from '../helpers/monitorDataConfirmationHooks';

interface IMonitorDataConfirmationScreenPropTypes
  extends IStyledComponentPropTypes {
  data: ISpreadsheetMappedData;
  siteMonitorClass: ISiteMonitorClass;
  onCancel: () => void;
  onImport: () => void;
}
interface IFieldLogProps {
  label: string;
  value: string;
}

function MonitorDataConfirmationScreen({
  data,
  siteMonitorClass,
  onCancel,
  onImport,
  className,
}: IMonitorDataConfirmationScreenPropTypes) {
  const monitorDataFields = useMonitorDataFields();
  const loadMonitorDataFields = useLoadMonitorDataFields();
  const validateAssetsState = useValidateAssetsState();
  const [missingAssetConfirmations, setMissingAssetConfirmation] = useState<
    IMissingAssetConfirmation[]
  >([]);
  const exceptionReportLimits = useExceptionReportLimits();
  const loadExceptionReportLimits = useLoadExceptionReportLimits();
  const validateAssets = useValidateAssets();
  const monitorData = useMonitorData();
  const resetMonitorDataState = useResetMonitorDataState();
  const importMonitorData = useImportMonitorData();
  const exceptionReportLimitGroups = useExceptionReportLimitsGroups(
    exceptionReportLimits.payload
  );
  const [fieldLogList, setFieldLogList] = useState<IFieldLogProps[]>([]);

  useLayoutEffect(() => {
    resetMonitorDataState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (monitorDataFields.status === RequestStatus.IDLE) {
      loadMonitorDataFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorDataFields]);

  useEffect(() => {
    loadExceptionReportLimits({
      relatedSiteMonitorClass: siteMonitorClass.recordId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteMonitorClass]);

  const assetField = useMemo(() => {
    return monitorDataFields.payload.find((field) => field.isAssetField);
  }, [monitorDataFields]);

  useEffect(() => {
    if (assetField) {
      const assets = data
        .map((obj) => obj[assetField.id])
        .filter((val) => !!val);
      validateAssets({
        assets,
        relatedSiteMonitorClass: siteMonitorClass.recordId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetField]);

  useEffect(() => {
    if (monitorData.status === RequestStatus.SUCCEEDED) {
      setTimeout(onImport, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorData]);

  function confirmMissingAsset(key: number, saveAssetToQB?: boolean) {
    setMissingAssetConfirmation([
      ...missingAssetConfirmations,
      { key, saveAssetToQB },
    ]);
  }

  const dataWithAssetInfo = useMemo(() => {
    if (assetField && validateAssetsState.status === RequestStatus.SUCCEEDED) {
      return data.map((obj) => ({
        ...obj,
        [ASSET_INFO_FIELD]: validateAssetsState.payload.find(
          (currentAssetValidationRecord) =>
            currentAssetValidationRecord.monitorId === obj[assetField.id]
        ),
      }));
    }
  }, [data, assetField, validateAssetsState]);

  const validRows = useMemo(() => {
    if (dataWithAssetInfo) {
      return dataWithAssetInfo.filter((obj) => obj[ASSET_INFO_FIELD]?.valid);
    }
  }, [dataWithAssetInfo]);

  const invalidRows = useMemo(() => {
    if (dataWithAssetInfo) {
      return dataWithAssetInfo.filter((obj) => !obj[ASSET_INFO_FIELD]?.valid);
    }
  }, [dataWithAssetInfo]);

  function fieldLogValidation(fieldLogList: IFieldLogProps[]) {
    if (fieldLogList.length === 0) {
      return false;
    }

    const thereIsAFieldLogButItIsEmpty = fieldLogList.some((fieldLog) => {
      if (fieldLog.value.length === 0) {
        return true;
      }

      return false;
    });

    return thereIsAFieldLogButItIsEmpty;
  }

  function handleSubmit() {
    const isFieldLogEmpty = fieldLogValidation(fieldLogList);

    if (
      invalidRows?.length === missingAssetConfirmations.length &&
      assetField &&
      !isFieldLogEmpty
    ) {
      const saveAssetConfirmations = missingAssetConfirmations.filter(
        ({ saveAssetToQB }) => saveAssetToQB
      );
      const invalidRowsWithSaveAssetConfirmations = saveAssetConfirmations.map(
        ({ key }) => invalidRows[key]
      );
      const monitorIdsToBeSaved = invalidRowsWithSaveAssetConfirmations.map(
        //@ts-ignore
        (row) => row[assetField.id]
      );
      const submissionRecords = [
        ...validRows!,
        ...invalidRowsWithSaveAssetConfirmations,
      ].map((row) => {
        const match = validateAssetsState.payload.find(
          (currentAssetValidationRecord) =>
            //@ts-ignore
            currentAssetValidationRecord.monitorId === row[assetField.id]
        );
        return {
          ...row,
          ...match,
        };
      });

      importMonitorData({
        records: submissionRecords,
        monitorIds: monitorIdsToBeSaved,
        relatedSiteMonitorClass: siteMonitorClass.recordId,
        fieldLog: fieldLogList,
      });
    }
  }

  const displayExceptionReportLimitTables = () =>
    exceptionReportLimits.status === RequestStatus.SUCCEEDED &&
    [
      ...Object.getOwnPropertySymbols(exceptionReportLimitGroups),
      ...Object.keys(exceptionReportLimitGroups),
    ].map((assetType, index) => (
      <>
        <ExceptionReportLimitsTable
          assetType={assetType}
          exceptionReportLimitsPayload={{
            limits: exceptionReportLimitGroups[assetType],
          }}
          className="mt-4"
          key={index}
        />
        {index !== Object.keys(exceptionReportLimitGroups).length - 1 && (
          <hr></hr>
        )}
      </>
    ));

  const canContinue = useMemo(
    () => invalidRows?.length === missingAssetConfirmations.length,
    [invalidRows, missingAssetConfirmations]
  );

  useEffect(() => {
    if (fieldLogList.length === 0 && siteMonitorClass) {
      const siteMonitorClassObjectKeys = Object.keys(siteMonitorClass);

      const fieldLogs = siteMonitorClassObjectKeys.reduce<IFieldLogProps[]>(
        (fieldLogs, key) => {
          if (key.includes('fieldLog') && siteMonitorClass[key]) {
            fieldLogs.push({
              label: siteMonitorClass[key].toString(),
              value: '',
            });

            return fieldLogs;
          }

          return fieldLogs;
        },
        []
      );

      if (fieldLogs.length > 0) {
        setFieldLogList(fieldLogs);
      }
    }
  }, [siteMonitorClass, fieldLogList]);

  const handleFieldLogChange = (key: string, value: string) => {
    const newFieldLogList = fieldLogList.map((fieldLog) => {
      if (fieldLog.label === key) {
        return {
          ...fieldLog,
          value,
        };
      }

      return fieldLog;
    });

    setFieldLogList(newFieldLogList);
  };

  return (
    <div className={className}>
      <h1 className="mb-3">Finalize Import</h1>
      <FieldlogForm
        fieldLogList={fieldLogList}
        onFieldLogChange={handleFieldLogChange}
      />
      {fieldLogValidation(fieldLogList) && (
        <span className="text-danger">* Field log is required</span>
      )}

      {displayExceptionReportLimitTables()}
      {isDefined(validRows) &&
      isDefined(invalidRows) &&
      isDefined(monitorDataFields.payload) ? (
        <>
          {invalidRows.length > 0 ? (
            <>
              <MappedDataTable
                //@ts-ignore
                data={invalidRows}
                valid={false}
                monitorDataFields={monitorDataFields.payload}
                missingAssetConfirmations={missingAssetConfirmations}
                exceptionReportLimitsGroups={exceptionReportLimitGroups}
                confirmMissingAsset={confirmMissingAsset}
              />
              <p className="mt-2 mb-3 small">
                {invalidRows.length} rows with missing asset.{' '}
                {invalidRows.length - missingAssetConfirmations.length} rows
                need confirmations.
              </p>
            </>
          ) : (
            <></>
          )}
          {validRows.length > 0 ? (
            <>
              <MappedDataTable
                //@ts-ignore
                data={validRows}
                valid={true}
                monitorDataFields={monitorDataFields.payload}
                exceptionReportLimitsGroups={exceptionReportLimitGroups}
              />
              <p className="mt-2 mb-3 small">
                {validRows.length +
                  missingAssetConfirmations.filter((c) => c.saveAssetToQB)
                    .length}{' '}
                rows to be imported.
              </p>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <ScreenLoader />
      )}
      {displayAlerts(
        monitorData.httpErrors &&
          monitorData.httpErrors.errors &&
          monitorData.httpErrors.errors.length !== 0
          ? monitorData.httpErrors.errors // Display monitorDataHttpErrors
          : [monitorData.httpErrors?.message] // Fall back to error.message if no errors[] provided
      )}
      {monitorData.status === RequestStatus.SUCCEEDED ? (
        <Alert
          data-test-id="spreadsheet-import-success"
          variant={Variant.SUCCESS}
        >
          All rows queued for import.
        </Alert>
      ) : monitorData.status === RequestStatus.LOADING ? (
        <ScreenLoader />
      ) : (
        <div className="mt-4">
          <Button
            onClick={handleSubmit}
            className="mr-3"
            disabled={!canContinue}
            data-test-id="confirm-spreadsheet-import"
          >
            Import
          </Button>
          <Button variant={Variant.LIGHT} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}
export default styled(MonitorDataConfirmationScreen)`
  .table-responsive {
    max-height: 30vh;
  }
`;
/* eslint-enable max-lines */
