import React, { useMemo } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { IStyledComponentPropTypes } from '../../common/types';
import { IExceptionReportLimits } from '../../state/modules/exceptionReportLimits';

interface IExceptionReportLimitsPropTypes extends IStyledComponentPropTypes {
  exceptionReportLimitsPayload: IExceptionReportLimits;
  assetType?: string | symbol;
}

function ExceptionReportLimitsTable({
  exceptionReportLimitsPayload,
  assetType,
  className,
}: IExceptionReportLimitsPropTypes) {
  const { limits, contacts } = exceptionReportLimitsPayload;
  const headersRow = useMemo(
    () => (
      <tr>
        <th className="no-border"></th>
        <th>Upper</th>
        <th>Lower</th>
      </tr>
    ),
    []
  );
  const bodyRows = useMemo(
    () =>
      limits.map((row, key) => (
        <tr key={key}>
          <td>{row.monitorPoint}</td>
          <td style={{ backgroundColor: row.upperLimitColour }}>
            {row.upperLimit || 'None'}
          </td>
          <td style={{ backgroundColor: row.lowerLimitColour }}>
            {row.lowerLimit || 'None'}
          </td>
        </tr>
      )),
    [limits]
  );
  return (
    <Row>
      <Col xs={4}>
        {assetType && <h5>{assetType}</h5>}
        <Table bordered className={className}>
          <thead>{headersRow}</thead>
          <tbody>{bodyRows}</tbody>
        </Table>
        {contacts && (
          <p className="mb-4 small">
            Emails to be contacted: {contacts.map((c) => c.email)}.
          </p>
        )}
      </Col>
    </Row>
  );
}

export default styled(ExceptionReportLimitsTable)`
  .no-border {
    border: none;
  }
`;
