import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { useMediaQuery } from '@react-hook/media-query';
import styled from 'styled-components';

interface Props {
  title?: ReactNode | undefined;
  content?: ReactNode | undefined;
  className?: string;
}

export const SideBarComponent: FunctionComponent<Props> = (props) => {
  const { content, children, title, className } = props;

  const [open, setOpen] = useState(undefined as boolean | undefined);
  const isDesktop = useMediaQuery('(min-width: 960px)');

  useEffect(() => {
    if (isDesktop !== undefined && open === undefined) setOpen(isDesktop);
  }, [isDesktop, open]);

  const isOpen = open === undefined ? isDesktop : open;

  return (
    <div
      className={[
        className,
        isOpen && 'open',
        isDesktop ? 'is-desktop' : 'is-not-desktop',
      ]
        .filter((v) => v)
        .join(' ')}
    >
      <nav className="sidebar media-print-display-none">
        <div className="title">{title}</div>
        <div className="sidebar-content">{content}</div>
      </nav>

      <button
        className="toggle media-print-display-none"
        onClick={() => setOpen(!isOpen)}
      >
        {open ? <GoChevronLeft size={24} /> : <GoChevronRight size={24} />}
      </button>

      <div className="content">{children}</div>
    </div>
  );
};

export const SideBar = styled(SideBarComponent)`
  position: relative;
  width: 100%;
  align-items: stretch;

  & > .sidebar {
    position: absolute;
    top: 16px;
    min-height: 0;
    left: -4em;
    opacity: 0;
    border-radius: 4px;
    transition: left 0.3s ease-out, opacity 0.3s, max-height 0.05s 0.4s,
      max-width 0.05s 0.4s;
    background: white;
    z-index: 1;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.3);
    max-width: 0;
    max-height: 1%;
    overflow: auto;
  }

  & > .sidebar > .title {
    position: relative;
    min-height: 32px;
    margin-top: 6px;
    margin-left: 32px;
  }

  &.is-not-desktop > .sidebar {
    max-width: 100%;
    top: 0;
    padding-top: 48px;
  }

  & > .toggle {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    transition: all 0.1s ease-out;
    z-index: 2;
    border-radius: 4px;
    border: none;
    outline: none;
    background: white;
    color: rgba(0, 0, 0, 0.9);
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.3);
    fontsize: 2em;
  }
  &.open > .toggle {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
  }
  &.open > .sidebar {
    min-height: 14em;
    left: 16px;
    opacity: 1;
    transition: left 0.1s 0.3s ease-out, opacity 0.1s 0.3s ease-out,
      max-height 0.05s, max-width 0.05s;
    max-height: 92%;
    max-width: 33%;
    overflow: none;
  }
  &.open.is-not-desktop > .sidebar {
    max-width: 100%;
    left: 0;
  }
`;
