import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
interface IFieldLogProps {
  label: string;
  value: string;
}

interface IFieldLogFormProps {
  fieldLogList: IFieldLogProps[];
  onFieldLogChange: (key: string, value: string) => void;
}

export const FieldlogForm = ({
  fieldLogList,
  onFieldLogChange,
}: IFieldLogFormProps) => {
  if (fieldLogList?.length > 0) {
    return (
      <div className="d-flex flex-column">
        <h4 className="text-body font-weight-normal">Field Log</h4>
        <div className="d-flex">
          {fieldLogList.map((fieldLog) => {
            return (
              <div key={fieldLog.label} className="form-group mr-4">
                <label htmlFor={fieldLog.label}>{fieldLog.label}:</label>
                <Form.Control
                  key={fieldLog.label}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    // @ts-ignore
                    onFieldLogChange(fieldLog.label, e.target.value);
                  }}
                  required
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};
