import React from 'react';
import { Container, Row } from 'react-bootstrap';

import { Spinner } from './RunEnergy';

export function ComponentLoader() {
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Spinner animation="grow"></Spinner>
        <Spinner animation="grow" className="ml-1"></Spinner>
        <Spinner animation="grow" className="ml-1"></Spinner>
      </Row>
    </>
  );
}

export function ScreenLoader() {
  return (
    <Container className="my-2">
      <ComponentLoader />
    </Container>
  );
}
