import createSlice from './helpers/createSlice';

// Types & Interfaces
export interface IMonitorDataImportJob {
  processing: number;
  startingSize: number;
  created: string;
  updated: string;
  failed?: object[];
}

// Slice Name
export const sliceName = 'monitorDataImportJobs';
export const url = 'monitor-data-import-jobs';

// Abstract Slice Instance
const monitorDataImportJobsSlice = createSlice<IMonitorDataImportJob[]>(
  sliceName,
  url
);

// Initial state
export const initialState = monitorDataImportJobsSlice.initialState;

// Slice Selectors Hooks
export const useMonitorDataImportJobs = monitorDataImportJobsSlice.useSlice;

// Slice Actions Hooks
export const useLoadMonitorDataImportJobs =
  monitorDataImportJobsSlice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors =
  monitorDataImportJobsSlice.useDismissHTTPErrors;

// Slice reducer
export default monitorDataImportJobsSlice.reducer;
