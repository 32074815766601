import moment from 'moment';
import objectHash from 'object-hash';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  IMonitorDataFeatureProperties,
  IMonitorDataQuery,
} from '../../state/modules/monitorData';
import { setUrlParams } from '../../utils/router';

export interface IMonitorDataFilterLocal {
  readonly fieldId: number;
  readonly section?: string;
  readonly min?: number;
  readonly max?: number;
}

export const mapStyles = {
  dark: 'mapbox://styles/mapbox/dark-v10',
  outdoors: 'mapbox://styles/mapbox/outdoors-v11',
  light: 'mapbox://styles/mapbox/light-v10',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
  navigation: 'mapbox://styles/mapbox/navigation-day-v1',
  streets: 'mapbox://styles/mapbox/streets-v11',
} as const;
export const defaultMapStyle: keyof typeof mapStyles = 'satellite';

export const getMapStyle = (style: keyof typeof mapStyles | string) =>
  mapStyles[style as keyof typeof mapStyles] || mapStyles[defaultMapStyle];

export interface IMonitorDataFilter
  extends Partial<IMonitorDataFilterLocal>,
    Omit<IMonitorDataQuery, 'siteId' | 'siteMonitorClassId'> {
  readonly siteMonitorClassId?: number;
  readonly style?: keyof typeof mapStyles | string;
  /**
   * https://github.com/Rylern/InterpolateHeatmapLayer#readme
   * A factor affecting the computation of the color.
   * A high value makes the color uniform around each point.
   */
  readonly p?: number;
}

export const readFilterFromUrl = (url: string): IMonitorDataFilter => {
  const query = new URLSearchParams(url);
  const siteMonitorClassIdStr = query.get('siteMonitorClassId');
  const section = query.get('section') || undefined;
  const fieldIdStr = query.get('fieldId');
  const minStr = query.get('min');
  const maxStr = query.get('max');
  const startDateStr = query.get('startDate');
  const endDateStr = query.get('endDate');
  const style = query.get('style') || undefined;
  const pStr = query.get('p');

  return {
    siteMonitorClassId: siteMonitorClassIdStr
      ? parseInt(siteMonitorClassIdStr, 10) || undefined
      : undefined,
    section,
    fieldId: fieldIdStr ? parseInt(fieldIdStr, 10) || undefined : undefined,

    min: minStr ? parseInt(minStr, 10) || undefined : undefined,
    max: maxStr ? parseInt(maxStr, 10) || undefined : undefined,

    startDate: startDateStr
      ? moment(startDateStr).format('YYYY-MM-DD')
      : undefined,
    endDate: endDateStr ? moment(endDateStr).format('YYYY-MM-DD') : undefined,

    style,
    p: pStr ? parseInt(pStr, 10) || undefined : undefined,
  };
};

export const useMonitorDataFilter = () => {
  const location = useLocation();
  const history = useHistory();

  const [filter, setFilter] = useState(() =>
    readFilterFromUrl(location.search)
  );
  const filterHash = useMemo(() => objectHash(filter), [filter]);

  const commitFilter = useCallback(() => {
    const currentFilterInURL = readFilterFromUrl(location.search);
    if (objectHash(currentFilterInURL) !== filterHash) {
      setUrlParams(history, location)(filter as {});
    }
  }, [filterHash, filter, location, history]);

  // Update params on URL change
  const lastFilterHash = useRef(filterHash);
  useEffect(() => {
    lastFilterHash.current = filterHash;
  }, [filterHash]);
  useEffect(() => {
    const newFilter = readFilterFromUrl(location.search);
    if (objectHash(newFilter) !== lastFilterHash.current) {
      setFilter(newFilter);
    }
  }, [location]);

  const setFilterField = useCallback(
    <F extends keyof IMonitorDataFilter>(field: F) =>
      (value: IMonitorDataFilter[F]) => {
        setFilter({
          ...filter,
          [field]: value,
        });
      },
    [filter]
  );

  return { filter, setFilter, setFilterField, commitFilter };
};

export const monitorDataQueryToFilter = ({
  siteId,
  ...filter
}: IMonitorDataQuery): Partial<IMonitorDataFilter> => filter;

type IMonitorDataPicked = GeoJSON.FeatureCollection<
  GeoJSON.Point,
  IMonitorDataFeatureProperties & { value: number }
>;

export const pickGeoData = (
  data: GeoJSON.FeatureCollection<GeoJSON.Point, IMonitorDataFeatureProperties>,
  filter: IMonitorDataFilterLocal
): IMonitorDataPicked => {
  const { section, fieldId } = filter;

  const features: IMonitorDataPicked['features'] = (
    section
      ? data.features.filter((f) => f.properties.section === section)
      : data.features
  )
    .map((feature) => {
      const value = feature.properties.values[fieldId];
      if (value === undefined) return undefined;
      else
        return {
          ...feature,
          properties: {
            value,
            ...feature.properties,
          },
        };
    })
    .filter(
      (feature): feature is NonNullable<typeof feature> =>
        feature?.properties.value !== undefined
    );

  return {
    ...data,
    features,
  };
};
