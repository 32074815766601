export const isEmpty = (
  obj:
    | undefined
    | null
    | string
    | number
    | Record<string, unknown>
    | Array<unknown>
) => {
  if (!obj && obj !== 0) {
    return true;
  } else if (Array.isArray(obj)) {
    return !obj.length;
  } else if (typeof obj === 'object') {
    return !Object.keys(obj || {}).length;
  }
  return false;
};
