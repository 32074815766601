const OPACITY_OPERATOR = 0.65;

/**
 * Value normalized to [0, 1], Colors: [0, 255], alpha [0-1]
 */
export type HeatMapGradient = readonly (readonly [
  value: number,
  r: number,
  g: number,
  b: number,
  a: number
])[];

export const heatmapGradientDefault: HeatMapGradient = [
  [0.15, 196, 181, 250, 1],
  [0.35, 185, 255, 250, 1],
  [0.5, 159, 254, 100, 1],
  [0.55, 159, 254, 100, 1],
  [0.75, 250, 251, 71, 1],
  [0.9, 245, 52, 45, 1],
];

const getColor = (item: HeatMapGradient[0]) =>
  `rgba(${item.slice(1).join(',')})`;

export const gradientColorByValue =
  (gradient: HeatMapGradient) => (value: number) => {
    const h =
      gradient.find(([v]) => value <= v) || gradient[gradient.length - 1];
    const l = gradient[gradient.indexOf(h) - 1] || gradient[0];
    const ratio =
      Math.abs(h[0] - l[0]) < 0.0001
        ? 1
        : (Math.min(value, h[0]) - l[0]) / (h[0] - l[0]);
    const rgba = [];
    for (let i = 1; i <= 4; i += 1)
      rgba.push(
        l[i] +
          ratio *
            (Math.max(l[i], h[i]) - Math.min(l[i], h[i])) *
            (l[i] < h[i] ? 1 : -1)
      );

    return rgba;
  };

export const gradientRangeCut =
  (gradient: HeatMapGradient) =>
  (min = 0, max = 1, spread = 0.1): HeatMapGradient => {
    const subGr = gradient.filter(([v]) => v > min && v < max);

    const minSpread = min - spread;
    const maxSpread = max + spread;

    const minColor = gradientColorByValue(gradient)(min);
    const minColorSpread = gradientColorByValue(gradient)(minSpread);

    const maxColor = gradientColorByValue(gradient)(max);
    const maxColorSpread = gradientColorByValue(gradient)(maxSpread);

    if (subGr.length === 0 || min < subGr[0][0])
      subGr.unshift([min, ...minColor] as unknown as HeatMapGradient[0]);

    if (spread !== 0 && minSpread < subGr[0][0])
      subGr.unshift([
        minSpread,
        ...minColorSpread.slice(0, 3),
        0,
      ] as unknown as HeatMapGradient[0]);

    if (subGr.length === 0 || max > subGr[subGr.length - 1][0])
      subGr.unshift([max, ...maxColor] as unknown as HeatMapGradient[0]);

    if (spread !== 0 && maxSpread > subGr[subGr.length - 1][0])
      subGr.push([
        maxSpread,
        ...maxColorSpread.slice(0, 3),
        0,
      ] as unknown as HeatMapGradient[0]);

    return subGr;
  };

export const gradientToCSSGradient = (
  gradient: HeatMapGradient,
  direction: string = 'to right'
) =>
  `linear-gradient(
        ${direction},
        ${getColor(gradient[0])} 0%,
        ${gradient
          .map((item) => `${getColor(item)} ${item[0] * 100}%`)
          .join(',\n')},
        ${getColor(gradient[gradient.length - 1])} 100%
    )`;

export const gradientToGLSLValueToColor4 = (gradient: HeatMapGradient) => {
  const gradientNormalized = [...gradient]
    .sort((a, b) => a[0] - b[0])
    .map((item) => [
      item[0],
      ...item.slice(1, 4).map((color) => color / 255),
      item[4] * OPACITY_OPERATOR,
    ]);

  const len = gradientNormalized.length;

  const glslGradientCascades = gradientNormalized.reduce(
    (res, item, index) => {
      const [vl, ...rgba] = item;
      const [vh] = gradientNormalized[index + 1] || item;

      const vls = vl.toFixed(4);
      const vhs = vh.toFixed(4);

      const isLast = index >= len - 1;

      const highs = rgba.map((c) =>
        isLast
          ? `return ${c.toFixed(1)};`
          : `if (value <= ${vls}) return ${c.toFixed(4)};`
      );
      const lows = rgba.map((c) =>
        isLast
          ? `return ${c.toFixed(1)};`
          : `if (value <= ${vhs}) return ${c.toFixed(4)};`
      );

      const ratioCalc =
        vh + 0.0001 <= vl || isLast
          ? `return 1.0;`
          : `if (value <= ${vhs}) return (min(value, ${vhs}) - ${vls}) / ${(
              vh - vl
            ).toFixed(4)};`;

      return {
        ratio: res.ratio + '\n\t\t' + ratioCalc,
        rh: res.rh + '\n\t\t' + highs[0],
        rl: res.rl + '\n\t\t' + lows[0],
        gh: res.gh + '\n\t\t' + highs[1],
        gl: res.gl + '\n\t\t' + lows[1],
        bh: res.bh + '\n\t\t' + highs[2],
        bl: res.bl + '\n\t\t' + lows[2],
        ah: res.ah + '\n\t\t' + highs[3],
        al: res.al + '\n\t\t' + lows[3],
      };
    },
    {
      ratio: '',
      rh: '',
      rl: '',
      gh: '',
      gl: '',
      bh: '',
      bl: '',
      ah: '',
      al: '',
    }
  );

  const glslGradientDefs = Object.keys(glslGradientCascades)
    .map(
      (key) =>
        `\n\tfloat valueToColor_gradient_${key}(float value) { ${
          glslGradientCascades[key as keyof typeof glslGradientCascades]
        }\n\t}`
    )
    .join('');

  return `
      ${glslGradientDefs}
    
      vec4 valueToColor4(float value, float defaultOpacity) {
    
        float ratio = valueToColor_gradient_ratio(value);
    
        float rl = valueToColor_gradient_rl(value);
        float rh = valueToColor_gradient_rh(value);
        float gl = valueToColor_gradient_gl(value);
        float gh = valueToColor_gradient_gh(value);
        float bl = valueToColor_gradient_bl(value);
        float bh = valueToColor_gradient_bh(value);
        float al = valueToColor_gradient_al(value);
        float ah = valueToColor_gradient_ah(value);
    
        float r = rl + ratio * (max(rl, rh) - min(rl, rh)) * (rl < rh ? 1.0 : -1.0);
        float g = gl + ratio * (max(gl, gh) - min(gl, gh)) * (gl < gh ? 1.0 : -1.0);
        float b = bl + ratio * (max(bl, bh) - min(bl, bh)) * (bl < bh ? 1.0 : -1.0);
        float a = al + ratio * (max(al, ah) - min(al, ah)) * (al < ah ? 1.0 : -1.0);
  
        return vec4(r, g, b, a);
      }
    `;
};
