import { useMemo } from 'react';
import {
  IExceptionReportLimit,
  IExceptionReportLimits,
} from '../../state/modules/exceptionReportLimits';

export const ASSET_INFO_FIELD = Symbol('asset info field'); // Use this field to store asset info inside each record
export const GENERAL_ASSET_TYPE = Symbol('general asset type'); // Use this to apply general exception limits to any asset type

export interface IExceptionReportLimitsGroups {
  [k: string | symbol]: IExceptionReportLimit[];
}

export function useExceptionReportLimitsGroups(
  exceptionReportLimits?: IExceptionReportLimits
) {
  return useMemo(
    () =>
      (
        exceptionReportLimits?.limits || []
      ).reduce<IExceptionReportLimitsGroups>((groups, limit) => {
        let { assetType } = limit;
        const key = assetType !== '' ? assetType : GENERAL_ASSET_TYPE;
        const match = groups[key];
        if (match) {
          match.push(limit);
        } else {
          groups[key] = [limit];
        }
        return groups;
      }, {}),
    [exceptionReportLimits]
  );
}
