import createSlice from './helpers/createSlice';

// Types & Interfaces
export interface ISiteMonitorClass {
  recordId: number;
  relatedSite: number;
  siteName: string;
  relatedMonitorClass: number;
  monitorClass: string;
  includeForHeatMaps: boolean;
  [key: string]: string | number | boolean;
}

// Slice Name
export const sliceName = 'siteMonitorClass';
export const url = 'site-monitor-classes';

// Abstract Slice Instance
const siteMonitorClassesSlice = createSlice<ISiteMonitorClass[]>(
  sliceName,
  url
);

// Initial state
export const initialState = siteMonitorClassesSlice.initialState;

// Slice Selectors Hooks
export const useSiteMonitorClasses = siteMonitorClassesSlice.useSlice;

// Slice Actions Hooks
export const useLoadSiteMonitorClasses =
  siteMonitorClassesSlice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors =
  siteMonitorClassesSlice.useDismissHTTPErrors;

// Slice reducer
export default siteMonitorClassesSlice.reducer;
