import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Image, Nav, Dropdown, Button } from 'react-bootstrap';
import { LogoutOptions } from '@auth0/auth0-react';

import { PRIMARY } from '../components/common/RunEnergy';
import { useAuth, IAuthUser } from '../state/modules/auth';
import { IVersion, useVersionSimple } from '../state/modules/version';
import { IStyledComponentPropTypes } from '../common/types';

const avatar =
  './user.svg';

type IUserInfoCardPropTypes = {
  user?: IAuthUser;
  logout: (options?: LogoutOptions) => void;
};

function UserInfoCard({ user, logout }: IUserInfoCardPropTypes) {
  const returnTo = process.env.REACT_APP_AUTH0_RETURN_TO;

  return (
    <Dropdown.Menu>
      <Container className="justify-content-center text-center">
        <Image
          src={avatar}
          alt={'profile photo'}
          roundedCircle
          width="83px"
          className="ml-5 mr-5 mt-3 mb-3"
        />
        <p data-test-id="Header-user-email">
          <strong>{user?.email}</strong>
        </p>
        <p>
          <small>{user?.name}</small>
        </p>
        <hr></hr>
        <Button
          onClick={() => logout({ returnTo })}
          className="mb-2"
          data-test-id="Header-logout"
        >
          <small>logout</small>
        </Button>
      </Container>
    </Dropdown.Menu>
  );
}

type IPicDropDownTogglePropTypes = {
  user?: IAuthUser;
};

function PicDropDownToggle({ user }: IPicDropDownTogglePropTypes) {
  return (
    <Dropdown.Toggle variant="link">
      <Image src={avatar} alt={'profile photo'} roundedCircle width="32px" />
    </Dropdown.Toggle>
  );
}

function Header({ className }: IStyledComponentPropTypes) {
  const { user, logout } = useAuth();
  const { payload: versionResponse } = useVersionSimple();
  const title = `Run Energy (${(versionResponse as IVersion)?.version})`;

  return (
    <Container as="nav" fluid className={className}>
      <Nav as="ul" activeKey="/home">
        <Nav.Item as="li">
          <NavLink to="/" tabIndex={-1}>
            <Image src="../logo.jpg" alt={title} title={title} height="60px" />
          </NavLink>
        </Nav.Item>
        <Nav.Item as="li">
          <Dropdown as="span">
            <PicDropDownToggle user={user} />
            <UserInfoCard user={user} logout={logout} />
          </Dropdown>
        </Nav.Item>
      </Nav>
    </Container>
  );
}

export default styled(Header)`
  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 8vh;
  }
  .nav-item {
    margin-right: 1rem;
  }
  .nav-item:last-child {
    margin-right: 35px;
    margin-left: auto;
  }
  .nav-item a.active {
    font-weight: bold;
  }
  .separator {
    border-left: 6px solid ${PRIMARY};
    height: 50px;
    margin-right: 2.6rem;
  }
  .dropdown-toggle {
    box-shadow: none !important;
  }
  .dropdown-toggle:active {
    box-shadow: none !important;
  }
  .dropdown-toggle::after {
    content: none;
  }
`;
