import React, { useMemo } from 'react';
import { Badge, Button, Row, Table } from 'react-bootstrap';
import styled from 'styled-components';
import {
  IDataField,
  IStyledComponentPropTypes,
  Variant,
} from '../../common/types';
import { ISpreadsheetMappedData } from '../../containers/Home';
import { IExceptionReportLimit } from '../../state/modules/exceptionReportLimits';
import {
  IExceptionReportLimitsGroups,
  GENERAL_ASSET_TYPE,
  ASSET_INFO_FIELD,
} from './monitorDataConfirmationHooks';
import { isDefined } from '../../utils/typeGuards';

export interface IMissingAssetConfirmation {
  key: number;
  saveAssetToQB?: boolean;
}

function getFieldOrder(field: IDataField) {
  return isDefined(field.order) ? field.order : Infinity;
}

function getCellBackgroundColor(
  exceptionReportLimit: IExceptionReportLimit | undefined,
  value: number | string | undefined
) {
  // NOTE - Blank values should not register exceptions
  if (exceptionReportLimit && isDefined(value) && value !== '') {
    if (Number(value) > exceptionReportLimit.upperLimit) {
      return exceptionReportLimit.upperLimitColour;
    } else if (Number(value) < exceptionReportLimit.lowerLimit) {
      return exceptionReportLimit.lowerLimitColour;
    }
  }
}

const sortFieldsByOrder = (field1: IDataField, field2: IDataField) =>
  getFieldOrder(field1) - getFieldOrder(field2);

interface ITablePropTypes extends IStyledComponentPropTypes {
  data: ISpreadsheetMappedData;
  valid: boolean;
  monitorDataFields: IDataField[];
  exceptionReportLimitsGroups: IExceptionReportLimitsGroups;
  missingAssetConfirmations?: IMissingAssetConfirmation[];
  confirmMissingAsset?: (key: number, saveAssetToQB?: boolean) => void;
}

function MappedDataTable({
  data,
  valid,
  className,
  monitorDataFields,
  exceptionReportLimitsGroups,
  missingAssetConfirmations = [],
  confirmMissingAsset = () => {},
}: ITablePropTypes) {
  const headersRow = useMemo(
    () => (
      <tr>
        <th>#</th>
        {!valid ? <th>Action Required</th> : <></>}
        {monitorDataFields && data[0] ? (
          Object.keys(data[0])
            .map((id) => {
              return monitorDataFields.find(
                (field) => field.id === Number(id)
              )!;
            })
            .sort(sortFieldsByOrder)
            .map((field, index) => {
              return <th key={index}>{field?.label}</th>;
            })
        ) : (
          <></>
        )}
      </tr>
    ),
    [data, valid, monitorDataFields]
  );
  const bodyRows = useMemo(
    () =>
      data.map((row, key) => {
        const missingAssetConfirmation = missingAssetConfirmations.find(
          (confimation) => confimation.key === key
        );
        const exceptionReportLimits =
          [...(exceptionReportLimitsGroups[GENERAL_ASSET_TYPE] || [])] || [];
        const assetExceptionReportLimits =
          //@ts-ignore
          row[ASSET_INFO_FIELD]?.assetType
            ? //@ts-ignore
              exceptionReportLimitsGroups[row[ASSET_INFO_FIELD].assetType] || []
            : [];
        // Following snippet is about extending the generalExceptionReport with asset specific ones for different monitorPoints
        assetExceptionReportLimits.forEach((assetLimit) => {
          const matchIndex = exceptionReportLimits.findIndex(
            (generalLimit) =>
              generalLimit.monitorPoint === assetLimit.monitorPoint
          );
          if (!!~matchIndex) {
            exceptionReportLimits[matchIndex] = assetLimit;
          } else {
            exceptionReportLimits.push(assetLimit);
          }
        });
        return (
          <tr key={key}>
            <td>{key + 1}</td>
            {!valid ? (
              <td>
                {!missingAssetConfirmation ? (
                  <Row className="p-3">
                    <Button
                      block
                      onClick={() => confirmMissingAsset(key)}
                      data-test-id="confirm-row-skip"
                    >
                      I confirm this row will not be imported
                    </Button>
                    <Button
                      block
                      onClick={() => confirmMissingAsset(key, true)}
                      data-test-id="confirm-row-add"
                      variant={Variant.SUCCESS}
                    >
                      I confirm to create new asset and inlude this row on
                      import
                    </Button>
                  </Row>
                ) : (
                  <p className="text-center">
                    Confirmed
                    {missingAssetConfirmation.saveAssetToQB && (
                      <>
                        <br />
                        <Badge className="ml-1" variant={Variant.SUCCESS}>
                          New Asset
                        </Badge>
                      </>
                    )}
                  </p>
                )}
              </td>
            ) : (
              <></>
            )}
            {Object.keys(row)
              .map((id) => {
                return monitorDataFields.find(
                  (field) => field.id === Number(id)
                )!;
              })
              .sort(sortFieldsByOrder)
              .map((field, index) => {
                const exceptionReportLimit = exceptionReportLimits.find(
                  (row) =>
                    row.monitorPoint.toLowerCase() ===
                    field?.label.toLowerCase()
                );
                const value = row[+field.id];
                const background = getCellBackgroundColor(
                  exceptionReportLimit,
                  value
                );
                return (
                  <td style={{ background }} key={index}>
                    {value}
                  </td>
                );
              })}
          </tr>
        );
      }),
    [
      data,
      valid,
      missingAssetConfirmations,
      confirmMissingAsset,
      monitorDataFields,
      exceptionReportLimitsGroups,
    ]
  );
  return (
    <Table bordered responsive className={className}>
      <thead>{headersRow}</thead>
      <tbody>{bodyRows}</tbody>
    </Table>
  );
}

export default styled(MappedDataTable)`
  thead {
    position: sticky;
    background-color: white;
    top: -1px;
    z-index: 2;
  }
  th:first-child,
  td:first-child {
    position: sticky;
    left: -2px;
    background-color: white;
    z-index: 2;
  }
  td:first-child {
    z-index: 1;
  }
  th {
    position: relative;
    z-index: 0;
    white-space: nowrap;
  }
`;
