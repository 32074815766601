import { combineReducers } from '../common';
import validateAssetsReducer, {
  sliceName as validateAssetsSliceName,
  initialState as validateAssetsInitialState,
} from './validateAssets';

// Slice Name
export const sliceName = 'assets';

// Initial state
export const initialState = {
  [validateAssetsSliceName]: validateAssetsInitialState,
};

// Slice reducer
export default combineReducers<typeof initialState>({
  [validateAssetsSliceName]: validateAssetsReducer,
});
