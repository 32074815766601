import { useCallOnceOnIDTokenGranted } from '../state/modules/common';

interface IEmptyResponse {
  payload: undefined;
  status: undefined;
}

export function createSimpleLoadHook<T>(
  useHook: (query?: Record<string, unknown>) => T,
  useLoadHook: (query?: Record<string, unknown>) => () => void
) {
  return (query?: Record<string, unknown>): T | IEmptyResponse => {
    const response: T = useHook(query);
    const loadMethod = useLoadHook(query);
    useCallOnceOnIDTokenGranted(loadMethod);
    return response || { payload: undefined, status: undefined };
  };
}
