/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import { Button, Spinner } from 'react-bootstrap';
import { RequestStatus } from '../common/types';
import { displayAPIErrors } from './helpers/displayAlerts';
import { MAP_DEFAULTS } from './helpers/HeatMapDefaults';
import { SideBar } from './SideBar';
import { HeatMapControls } from './MonitorData/Controls';
import { getMapStyle } from './MonitorData/Filter';
import { addBaseLayers, FieldsInfo } from './HeatMap/baseLayers';
import { monitorDataHeatMapLayer } from './HeatMap/layer';
import {
  gradientRangeCut,
  gradientToGLSLValueToColor4,
  heatmapGradientDefault,
} from './HeatMap/Gradient';
import { HeatMapLegend } from './HeatMap/Legend';
import { useMonitorDataProcess } from './MonitorData/Process';
import { MonitorDataCaption } from './MonitorData/Caption';
import './HeatMap.css';
import { isCoordsFlipped } from './normalizeCoords';
import { FeatureCollection } from 'geojson';

const baseLayerIds = [
  MAP_DEFAULTS.LAYERS.ASSET_POINT.id,
  MAP_DEFAULTS.LAYERS.ASSET_LABEL.id,
  /* MAP_DEFAULTS.LAYERS.ASSET_ID.id, */
];

const getAllLayerIds = (map: mapboxgl.Map) => {
  // @deprecated
  // mapbox-gl@2.4.2 + interpolateheatmaplayer@1.0.2
  // Sometimes official list `map.getStyle().layers`
  // doesn't contain heatmap layer.
  return Object.keys((map as any).style._layers);
};

const gradient = heatmapGradientDefault;

const flipFeatureCoords = <T extends FeatureCollection<GeoJSON.Point>>(
  collection: T
): T => ({
  ...collection,
  features: collection.features.map((feature) => ({
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [
        feature.geometry.coordinates[1],
        feature.geometry.coordinates[0],
      ],
    },
  })),
});

const isFeatureLikelyFlipped = <T extends FeatureCollection<GeoJSON.Point>>(
  collection: T
) => {
  if (collection.features.length === 0) return 'absolutely no';

  const statuses = collection.features
    .map((f) => f.geometry.coordinates as [number, number])
    .map(isCoordsFlipped);
  if (statuses.some((s) => s === 'absolutely no')) return 'absolutely no';
  if (statuses.some((s) => s === 'definitely')) return 'definitely';
  if (statuses.every((s) => s === 'not likely')) return 'not likely';

  return 'likely';
};

function HeatMap() {
  const mapContainer = useRef(null);
  const [map, setMap] = useState<mapboxgl.Map>();
  useEffect(() => {
    if (map) {
      return () => map.remove();
    }
  }, [map]);

  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapBaseReady, setMapBaseReady] = useState(false);
  const [forceFlip, setForceFlip] = useState<boolean>();

  const mapReadyForData = mapLoaded && mapBaseReady;

  const {
    geoData,
    geoDataCenter,
    siteBoundary,
    sections,
    monitorDataStatus,
    fields,
    fieldsById,
    fieldsStatus,
    selectedField,
    selectedFieldFormatValue,
    listing,
    listingStatus,
    filter,
    setFilter,
    handleQuery,
    selectedRangeNormalized,
    handleSelectedRangeNormalizedChange,
    errors,
  } = useMonitorDataProcess();

  const { fieldId, style, p } = filter;

  const gradientCut = useMemo(
    () => gradientRangeCut(gradient)(...selectedRangeNormalized),
    [selectedRangeNormalized]
  );
  const valueToColor4 = useMemo(
    () => gradientToGLSLValueToColor4(gradientCut),
    [gradientCut]
  );

  const mapStyle = useRef(style);

  // Storing info in Ref to use in closure.
  const fieldsInfoRef = useRef<FieldsInfo>({
    fieldsById,
    selectedFieldId: fieldId,
  });
  useEffect(() => {
    fieldsInfoRef.current = { fieldsById, selectedFieldId: fieldId };
  }, [fieldsById, fieldId]);

  useEffect(() => {
    if (!map && mapContainer.current) {
      const map = new mapboxgl.Map({
        container: mapContainer.current!,
        style: getMapStyle(mapStyle.current || ''),
        center: MAP_DEFAULTS.CENTER,
        zoom: MAP_DEFAULTS.ZOOM,
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        // To make printing work in Firefox
        preserveDrawingBuffer: true,
      });

      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl());

      map.on('load', () => {
        setMapLoaded(true);
      });

      map.on('styledata', () => {
        // Detecting base layers remove here
        const layerIds = getAllLayerIds(map);
        if (baseLayerIds.some((id) => !layerIds.includes(id))) {
          setMapBaseReady(false);
        }
      });

      setMap(map);
    }
  }, [map]);

  useEffect(() => {
    if (map && mapLoaded && !mapBaseReady) {
      addBaseLayers({
        map,
        fieldsInfoRef,
      });
      setMapBaseReady(true);
    }
  }, [map, mapLoaded, mapBaseReady]);

  useEffect(() => {
    if (map && style && mapBaseReady) {
      if (style !== mapStyle.current) {
        mapStyle.current = style;
        map.setStyle(getMapStyle(style));
      }
    }
  }, [map, style, mapBaseReady]);

  const autoFlipCenter = useMemo(
    () => (geoDataCenter ? isCoordsFlipped(geoDataCenter) : undefined),
    [geoDataCenter]
  );

  const autoFlip = useMemo(
    () =>
      geoData ? autoFlipCenter || isFeatureLikelyFlipped(geoData) : undefined,
    [geoData, autoFlipCenter]
  );

  const flip =
    forceFlip !== undefined
      ? !!forceFlip
      : autoFlip === 'likely' || autoFlip === 'definitely';

  useEffect(() => {
    if (
      map &&
      mapReadyForData &&
      monitorDataStatus === RequestStatus.SUCCEEDED &&
      geoData
    ) {
      if (geoDataCenter) {
        map.setCenter(
          flip ? [geoDataCenter[1], geoDataCenter[0]] : geoDataCenter
        );
      }
    }
  }, [flip, map, geoData, geoDataCenter, monitorDataStatus, mapReadyForData]);

  useEffect(() => {
    if (
      map &&
      mapReadyForData &&
      monitorDataStatus === RequestStatus.SUCCEEDED &&
      geoData
    ) {
      const assetsSource = map.getSource('assets') as GeoJSONSource;

      const geoDataNormalized: typeof geoData = flip
        ? flipFeatureCoords(geoData)
        : geoData;

      assetsSource.setData(geoDataNormalized);

      const heatmapLayer = monitorDataHeatMapLayer({
        id: 'heatmap',
        geoData: geoDataNormalized,
        siteBoundary,
        p,
        valueToColor4,
      });

      if (map.getLayer('heatmap')) {
        map.removeLayer('heatmap');
      }
      map.addLayer(heatmapLayer, MAP_DEFAULTS.LAYERS.ASSET_POINT.id);
      map.addLayer(heatmapLayer, MAP_DEFAULTS.LAYERS.ASSET_LABEL.id);

      return () => {
        if (map.getLayer('heatmap')) {
          map.removeLayer('heatmap');
          map.triggerRepaint();
        }
      };
    }
  }, [
    flip,
    map,
    geoData,
    monitorDataStatus,
    mapReadyForData,
    siteBoundary,
    p,
    valueToColor4,
  ]);

  const loadings = [
    listingStatus === RequestStatus.LOADING && 'sites list',
    fieldsStatus === RequestStatus.LOADING && 'points',
    monitorDataStatus === RequestStatus.LOADING && 'monitor data',
    !mapReadyForData && 'map',
  ].filter((msg) => !!msg);

  const legend = gradient && selectedField && (
    <div
      style={{
        position: 'absolute',
        right: 20,
        bottom: 30,
      }}
    >
      <HeatMapLegend
        caption={
          <div>
            <b>{selectedField.label}</b>
            {selectedField.properties?.units ? (
              <small>&nbsp;{selectedField.properties?.units}</small>
            ) : undefined}
          </div>
        }
        formatValue={selectedFieldFormatValue}
        gradient={gradient}
        gradientStyle={{ width: 284, minHeight: 40 }}
        selectedRange={selectedRangeNormalized}
        onSelectedRangeChange={handleSelectedRangeNormalizedChange}
      />
    </div>
  );

  let flipTool;
  if (geoData && autoFlip !== 'definitely' && autoFlip !== 'absolutely no')
    flipTool = (
      <div className="flip-tools" onClick={() => setForceFlip(!forceFlip)}>
        If the map shows unknown place (ocean?), click here!
        {
          <Button onClick={() => setForceFlip(!forceFlip)}>
            Flip coordinates
          </Button>
        }
        <small>Sorry about that, we working on it.</small>
      </div>
    );

  useEffect(() => {
    if (autoFlip === 'absolutely no' || autoFlip === 'definitely')
      setForceFlip(undefined);
  }, [autoFlip]);

  return (
    <div style={{ position: 'relative' }}>
      {flipTool}
      <MonitorDataCaption
        filter={filter}
        fields={fields}
        sections={sections}
        listing={listing}
        style={{ display: 'none' }}
        className="media-print-display-block"
      />
      {displayAPIErrors(...errors)}
      <SideBar
        title={
          loadings.length > 0 && (
            <div
              style={{
                zoom: 0.6,
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <Spinner animation="grow"></Spinner>&nbsp;
              <i className="text-muted">Loading {loadings.join(', ')}...</i>
            </div>
          )
        }
        content={
          <div style={{ position: 'relative' }}>
            <HeatMapControls
              filter={filter}
              fields={fields}
              sections={sections}
              listing={listing}
              onFilterChange={setFilter}
              onQuery={handleQuery}
            />
          </div>
        }
      >
        <div
          ref={mapContainer}
          className="map-container media-print-color-exact"
          style={{ background: 'black' }}
        />
      </SideBar>
      {legend}
    </div>
  );
}

export default HeatMap;
