import createSlice from './helpers/createSlice';

// Types & Interfaces
export interface ICustomer {
  recordId: number;
  name: string;
  email: string;
}

// Slice Name
export const sliceName = 'customers';

// Abstract Slice Instance
const customersSlice = createSlice<ICustomer[]>(sliceName);

// Initial state
export const initialState = customersSlice.initialState;

// Slice Selectors Hooks
export const useCustomers = customersSlice.useSlice;

// Slice Actions Hooks
export const useLoadCustomers = customersSlice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors =
  customersSlice.useDismissHTTPErrors;

// Slice reducer
export default customersSlice.reducer;
