import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import {
  fieldType,
  IDataField,
  IStyledComponentPropTypes,
  RequestStatus,
} from '../common/types';
import {
  ISpreadsheetFormData,
  ISpreadsheetMappedData,
} from '../containers/Home';
import {
  useLoadMonitorDataFields,
  useMonitorDataFields,
} from '../state/modules/monitorDataFields';
import { trimCommas } from '../utils/string';
import { isDefined } from '../utils/typeGuards';
import { ScreenLoader } from './common/Loaders';
import FieldMapModal from './helpers/FieldMapModal';
import Spreadsheet, {
  ISpreadsheetDataRows,
  ISpreadsheetHeader,
  ISpreadsheetHeaders,
} from './helpers/Spreadsheet';

function getHeaderOrder(header: ISpreadsheetHeader): number {
  let order = Infinity;
  if (isDefined(header[4])) {
    order = header[4]; // Overwrite order
  } else if (isDefined(header[2]) && isDefined(header[2].order)) {
    order = header[2].order; // Order from match field
  }
  return order;
}

function hasMatchingFieldLabel(headerName: string, field: IDataField) {
  return ~[field.label, ...(field.otherLabels || [])].findIndex(
    (label) => headerName.toLowerCase() === label.toLowerCase()
  );
}

interface IMonitorDataPropTypes extends IStyledComponentPropTypes {
  spreadsheetFormData: ISpreadsheetFormData;
  onSubmit: (data: ISpreadsheetMappedData) => void;
}

function MonitorDataTable({
  spreadsheetFormData,
  onSubmit,
  className,
}: IMonitorDataPropTypes) {
  const [spreadsheetHeaders, setSpreadsheetHeaders] =
    useState<ISpreadsheetHeaders>();
  const [spreadsheetDataRows, setSpreadsheetDataRows] =
    useState<ISpreadsheetDataRows>();

  const monitorDataFields = useMonitorDataFields();
  const loadMonitorDataFields = useLoadMonitorDataFields();

  const [selectedHeaderToMap, setSelectedHeaderToMap] = useState<number>();

  useEffect(() => {
    if (monitorDataFields.status === RequestStatus.IDLE) {
      loadMonitorDataFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorDataFields]);

  // the following hook is to auto map the fields when loaded
  useLayoutEffect(() => {
    if (
      monitorDataFields.payload &&
      spreadsheetHeaders &&
      spreadsheetDataRows
    ) {
      const updatedHeaders: ISpreadsheetHeaders = spreadsheetHeaders.map(
        (header) => [...header]
      ); // deep copy headers
      monitorDataFields.payload.forEach((field) => {
        const match = updatedHeaders.find((header) =>
          hasMatchingFieldLabel(header[0], field)
        );
        if (match) {
          if (
            field.fieldType !== ('timestamp' as fieldType) &&
            field.fieldType !== ('date' as fieldType)
          ) {
            // exclude date field from auto mapping so that user confirms the date format
            match[2] = field;
          } else {
            match[4] = field.order; // This ensures date fields showup in the intended order but unmatched
          }
          match[1] = true;
        }
      });

      // NOTE - We use the following spread operators to simply copy the array as sort has an un-pure attiude and will change the refence array otherwise
      const sortedUpdatedHeaders = [...updatedHeaders].sort(
        (header1, header2) => getHeaderOrder(header1) - getHeaderOrder(header2)
      );
      const sortedRows = spreadsheetDataRows.map((row) =>
        // Same spread operator note here
        [...row]
          .map((value, index) => ({
            value,
            order: getHeaderOrder(updatedHeaders[index]),
          }))
          .sort(({ order: order1 }, { order: order2 }) => order1 - order2)
          .map(({ value }) => value)
      );

      setSpreadsheetHeaders(sortedUpdatedHeaders);
      setSpreadsheetDataRows(sortedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorDataFields.payload]);

  useEffect(() => {
    if (spreadsheetFormData) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const rows = String(e.target?.result)
          .split(/[\n\r]+/g)
          .slice(spreadsheetFormData.headerRow - 1)
          .filter((row) => row !== '') // filter empty rows
          .map((row) => trimCommas(row).split(','))
          .filter((row) => !row.every((val) => !val || !(val || '').trim()));

        // NOTE - This is thanks to the slice operation we do above.
        const headerRow = 0;
        const headers = rows[headerRow].map(
          (value) => [value, false] as ISpreadsheetHeader
        );
        const data = rows.filter((_, index) => index !== headerRow);
        setSpreadsheetHeaders(headers);
        setSpreadsheetDataRows(data);
      };
      reader.readAsText(spreadsheetFormData.file);
    }
  }, [spreadsheetFormData]);

  function handleSpreadsheetHeaderChange(
    target: number,
    updatedHeader: ISpreadsheetHeader
  ) {
    const updatedHeaders = spreadsheetHeaders!.map((current, index) =>
      index === target ? updatedHeader : current
    ) as ISpreadsheetHeaders;
    setSpreadsheetHeaders(updatedHeaders);
  }

  function handleSpredSheetHeaderClick(target: number) {
    setSelectedHeaderToMap(target);
  }

  function handleFieldMapModalConfirmation(
    field: IDataField,
    cb?: (v: any) => any
  ) {
    const [label] = spreadsheetHeaders![selectedHeaderToMap!];
    handleSpreadsheetHeaderChange(selectedHeaderToMap!, [
      label,
      true,
      field,
      cb,
    ]);
    handleFieldMapModalCancel();
  }

  function handleFieldMapModalCancel() {
    setSelectedHeaderToMap(undefined);
  }

  function handleImport() {
    if (spreadsheetDataRows && spreadsheetHeaders) {
      const data: ISpreadsheetMappedData = spreadsheetDataRows.map((row) =>
        row.reduce((result, cur, index) => {
          const [, enabled, matchField, cb] = spreadsheetHeaders[index];
          if (enabled && isDefined(matchField)) {
            result[matchField.id] = cb ? cb(cur) : cur;
          }
          return result;
        }, {})
      );
      onSubmit(data);
    }
  }

  const blockedFields = useMemo(
    () =>
      spreadsheetHeaders
        ?.filter((_, index) => index !== selectedHeaderToMap)
        .map((header) => header[2])
        .filter(isDefined)
        .map((field) => field.id),
    [spreadsheetHeaders, selectedHeaderToMap]
  );

  const numColToBeImported = useMemo(
    () => spreadsheetHeaders?.filter((header) => header[1]).length || 0,
    [spreadsheetHeaders]
  );

  const canContinue = useMemo(
    () =>
      // the following makes sure all enabled headers are mapped
      spreadsheetHeaders
        ?.filter((header) => header[1])
        .reduce((result, header) => isDefined(header[2]) && result, true) &&
      // the following makes sure all required fields (asset) exist
      monitorDataFields.payload
        ?.filter((field) => field.required)
        .reduce(
          (result, field) =>
            !!~spreadsheetHeaders.findIndex((header) => field === header[2]) &&
            result,
          true
        ) &&
      monitorDataFields.status === RequestStatus.SUCCEEDED,
    [spreadsheetHeaders, monitorDataFields]
  );

  return isDefined(spreadsheetHeaders) &&
    isDefined(spreadsheetDataRows) &&
    monitorDataFields.status === RequestStatus.SUCCEEDED ? (
    <div className={className}>
      <h1>Match columns to monitor points</h1>
      {monitorDataFields.status === RequestStatus.SUCCEEDED ? (
        <>
          <p className="mt-3 mb-4">
            <strong>{spreadsheetDataRows.length}</strong> rows were recognized
            in this file
          </p>
          <Spreadsheet
            headers={spreadsheetHeaders}
            data={spreadsheetDataRows}
            onHeaderChange={handleSpreadsheetHeaderChange}
            onHeaderClick={handleSpredSheetHeaderClick}
          />

          <p className="mt-2 mb-3 small">
            {numColToBeImported} columns to be imported.{' '}
            {spreadsheetHeaders.length - numColToBeImported} columns will not be
            imported.
          </p>
        </>
      ) : (
        <ScreenLoader />
      )}
      <Button
        disabled={!canContinue}
        onClick={handleImport}
        data-test-id="continue-to-finalize-import-btn"
      >
        Continue To Finalize Import
      </Button>
      {isDefined(selectedHeaderToMap) &&
        isDefined(spreadsheetHeaders) &&
        isDefined(spreadsheetDataRows) && (
          <FieldMapModal
            onConfirm={handleFieldMapModalConfirmation}
            onCancel={handleFieldMapModalCancel}
            spreadsheetHeaders={spreadsheetHeaders}
            spreadsheetDataRows={spreadsheetDataRows}
            selectedHeaderToMap={selectedHeaderToMap}
            blockedFields={blockedFields || []}
          />
        )}
    </div>
  ) : (
    <ScreenLoader />
  );
}

export default styled(MonitorDataTable)`
  .table-responsive {
    height: 60vh;
  }
`;
