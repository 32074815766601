export function generateDefaultBoundaryPoints(
  dataPoints?: { lon: number; lat: number }[]
) {
  if (!dataPoints || dataPoints.length < 2) return undefined;

  // As effective center of our data cluster,
  // we'll use center of mass of data points,
  // (assuming equal mass for data points)
  const massVectorSum = dataPoints.reduce(
    ({ lon, lat }, sum) => ({
      lon: sum.lon + lon,
      lat: sum.lat + lat,
    }),
    { lon: 0, lat: 0 }
  );
  const massCenter = {
    lon: massVectorSum.lon / dataPoints.length,
    lat: massVectorSum.lat / dataPoints.length,
  };

  // Approx ratio between lon and lat in the area of interest.
  // Calculated as ratio between lengths (km) of a degree (lon[km per degree]/lat[km per degree]).
  // We use it to get rid of coordinate "squeezing" at different latitudes.
  const lonLatRatio =
    110.574 / (111.32 * Math.cos((Math.PI * massCenter.lat) / 180));

  // Computing distances from center of the cluster.
  // Distance is in "local longitudes", as we apply lon/lat ratio for normalization.
  const dataPointsDistances = dataPoints.map(({ lon, lat }) =>
    Math.sqrt(
      (massCenter.lon - lon) ** 2 + ((massCenter.lat - lat) * lonLatRatio) ** 2
    )
  );

  const biggestDataPointDistance = Math.max(0, ...dataPointsDistances);

  // To express gas dissolving, we enclosure data cluster by a boundary.
  // At this boundary we assume gas level to be 0 (fully dissolved).
  // This zero-points boundary provides info for the interpolation algorithm we use.
  const boundaryRadius = biggestDataPointDistance * Math.sqrt(2);

  // Here we generate a bunch of zero points on our boundary.
  const boundaryGranularity = 32;
  const boundaryPoints = [];
  for (let i = 0; i < boundaryGranularity; i += 1) {
    boundaryPoints.push({
      lon:
        massCenter.lon +
        boundaryRadius * Math.cos((2 * i * Math.PI) / boundaryGranularity),
      lat:
        massCenter.lat +
        (boundaryRadius * Math.sin((2 * i * Math.PI) / boundaryGranularity)) /
          lonLatRatio,
      val: 0,
    });
  }

  return boundaryPoints;
}
