import { chain } from 'lodash';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { MonitorDataListing } from '../../state/modules/monitorDataListing';
import { IMonitorDataFilter } from './Filter';

interface Props {
  siteId?: number | null;
  onSiteIdChange: (selectedSiteId?: number | null) => void;
  filter: IMonitorDataFilter;
  onFilterChange: (newFilter: IMonitorDataFilter) => void;
  listing?: MonitorDataListing;
}

export const MonitorDataQueryEditor: FunctionComponent<Props> = (props) => {
  const {
    siteId,
    onSiteIdChange,
    filter,
    onFilterChange,
    listing: { siteMonitorClasses } = {},
  } = props;

  const { siteMonitorClassId, startDate, endDate } = filter;

  const handleChange = useCallback(
    (fieldName: keyof IMonitorDataFilter) =>
      (newValue: IMonitorDataFilter[typeof fieldName]) =>
        onFilterChange({
          ...filter,
          [fieldName]: newValue,
        }),
    [filter, onFilterChange]
  );

  const [monitorId, setMonitorId] = useState<number>();

  const handleSiteIdChange = useCallback(
    (siteId: number | null) => {
      setMonitorId(undefined);
      onSiteIdChange(siteId);
    },
    [onSiteIdChange]
  );

  useEffect(() => {
    if (
      monitorId === undefined &&
      siteId === undefined &&
      siteMonitorClassId &&
      siteMonitorClasses
    ) {
      const selectedSiteMonitorClass = siteMonitorClasses.find(
        ({ recordId }) => recordId === siteMonitorClassId
      );
      if (selectedSiteMonitorClass) {
        onSiteIdChange(selectedSiteMonitorClass.relatedSite);
        setMonitorId(selectedSiteMonitorClass.relatedMonitorClass);
      }
    }
  }, [
    monitorId,
    siteId,
    siteMonitorClasses,
    siteMonitorClassId,
    handleChange,
    onSiteIdChange,
  ]);

  useEffect(() => {
    if (monitorId !== undefined && siteId === undefined) {
      setMonitorId(undefined);
    }
  }, [monitorId, siteId]);

  useEffect(() => {
    if (monitorId !== undefined && siteId !== undefined && siteMonitorClasses) {
      const selectedSiteMonitorClass = siteMonitorClasses.find(
        ({ relatedSite, relatedMonitorClass }) =>
          relatedSite === siteId && relatedMonitorClass === monitorId
      );
      if (!selectedSiteMonitorClass) {
        setMonitorId(undefined);
      } else {
        const newSiteMonitorClassId = selectedSiteMonitorClass.recordId;
        if (newSiteMonitorClassId !== siteMonitorClassId) {
          onFilterChange({
            ...filter,
            siteMonitorClassId: newSiteMonitorClassId,
          });
        }
      }
    }
  }, [
    monitorId,
    siteId,
    siteMonitorClassId,
    siteMonitorClasses,
    filter,
    onFilterChange,
  ]);

  const sites = useMemo(
    () =>
      siteMonitorClasses
        ? chain(siteMonitorClasses)
            .filter(({ includeForHeatMaps }) => includeForHeatMaps)
            .map(({ relatedSite, siteName }) => ({
              title: siteName,
              id: relatedSite,
            }))
            .uniqBy('id')
            .sortBy('title')
            .value()
        : [],
    [siteMonitorClasses]
  );

  const monitorClasses = useMemo(
    () =>
      siteMonitorClasses && siteId !== undefined
        ? siteMonitorClasses
            .filter(
              ({ relatedSite, includeForHeatMaps }) =>
                relatedSite === siteId && includeForHeatMaps
            )
            .map(({ relatedMonitorClass, monitorClass }) => ({
              title: monitorClass,
              id: relatedMonitorClass,
            }))
        : [],
    [siteMonitorClasses, siteId]
  );

  useEffect(() => {
    if (
      siteId !== undefined &&
      monitorId === undefined &&
      monitorClasses.length === 1
    ) {
      setMonitorId(monitorClasses[0].id);
    }
  }, [siteId, monitorId, monitorClasses]);

  return (
    <>
      <Form.Group>
        <Form.Label>Site</Form.Label>
        <Form.Control
          as="select"
          value={typeof siteId === 'number' ? siteId : ''}
          onChange={(e) =>
            handleSiteIdChange(parseInt(e.currentTarget.value, 10) || null)
          }
        >
          <option key="empty" value={''}></option>
          {sites.map(({ title, id }) => (
            <option key={id} value={id}>
              {title || `ID: ${id}`}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Monitor class</Form.Label>
        <Form.Control
          as="select"
          value={typeof monitorId === 'number' ? monitorId : ''}
          onChange={(e) =>
            setMonitorId(parseInt(e.currentTarget.value, 10) || undefined)
          }
        >
          <option key="empty" value={''}></option>
          {monitorClasses.map(({ title, id }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <div style={{ display: 'flex' }}>
        <Form.Group style={{ flex: 1 }}>
          <Form.Label>Start date</Form.Label>
          <Form.Control
            type="date"
            value={startDate}
            onChange={(e) =>
              handleChange('startDate')(e.currentTarget.value || undefined)
            }
          />
        </Form.Group>
        &nbsp;
        <Form.Group style={{ flex: 1 }}>
          <Form.Label>End date</Form.Label>
          <Form.Control
            type="date"
            value={endDate}
            onChange={(e) =>
              handleChange('endDate')(e.currentTarget.value || undefined)
            }
          />
        </Form.Group>
      </div>
    </>
  );
};
